import React, { PureComponent, createRef } from 'react'
import throttle from 'lodash/throttle'

import DesktopUserPanel from '../../User/DesktopUserPanel'
import Link from '../../common/Link/Link'
import SiteSettingPanel from '../SiteSettingPanel/SiteSettingPanel'
import SelectedCountrySite from '../SiteSettingPanel/SelectedCountrySite'
import FreeShippingBanner from '../../Widget/FreeShippingBanner/FreeShippingBanner'
import DesktopCartPreviewDrawer from '../../Store/Cart/DesktopCartPreviewDrawer.js'
import withAppState from '../../../hooks/withAppState'

import cx from '../../../utils/className'
import styles from './DesktopHeader.module.css'

const FloatPanel = props => {
  return (
    <div className={cx(styles.floatPanel, props.className)}>
      { props.children }
    </div>
  )
}

const ITEMS = [{
  key: 'user',
  to: 'Order',
  floatPanelContent: <DesktopUserPanel />,
  style: styles.user
}, {
  key: 'cart',
  floatPanelContent: <DesktopCartPreviewDrawer />,
  style: styles.cart,
  panelStyle: styles.cart,
}, {
  key: 'wishlist',
  to: 'WishList',
  style: styles.wishlist
}, {
  key: 'site',
  content: <SelectedCountrySite className={styles.country}/>,
  floatPanelContent: <SiteSettingPanel />
}]


class DesktopHeader extends PureComponent {

  state = {
    currentItem: null,
    fixTop: false,
  }

  headerRef = createRef()
  cartAutoCloseTimer = null

  handleCartChange = params => {
    if (params.cart &&  params.cart.itemQuantity > 0 && params.cartOperation.add) {
      this.setState({
        currentItem: ITEMS[1]
      })

      clearTimeout(this.cartAutoCloseTimer)

      this.cartAutoCloseTimer = setTimeout(() => {
        this.setState({
          currentItem: null
        })
      }, 3000)
    }
  }


  componentDidMount() {
    this.props.$store && this.props.$store.subscribeCart(this.handleCartChange)
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    this.props.$store && this.props.$store.unsubscribeCart(this.handleCartChange)
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = throttle(() => {
    if (this.headerRef.current) {
      this.setState({
        fixTop: window.pageYOffset > this.headerRef.current.offsetHeight + this.headerRef.current.offsetTop
      })
    }
  }, 500)

  handleItemHover = item => {
    this.setState({
      currentItem: item
    })

    // 若发现当前hover的是cart, 需要关闭之前可能存在的3s购物车弹窗自动关闭的句柄
    if (item && item.key === 'cart' && this.cartAutoCloseTimer) {
      clearTimeout(this.cartAutoCloseTimer)
    }
  }

  handleItemClick = (item, ev) => {
    if (!item.to) {
      ev.preventDefault()

      if (item && item.key === 'cart') {
        this.props.onCartClick()
      }
    }
  }

  render () {
    const elements = []
    const { site } = this.props
    const logoStyle = {}

    if (site.logo) {
      logoStyle["backgroundImage"] = `url(${site.logo})`
    }

    elements.push(
      <Link
        key='logo'
        to='Home'
        className={styles.title}
        style={logoStyle}
      ></Link>
    )

    return (
      <>
        <div className={styles.freeShippingBar} key='freeShippingBanner'>
          <FreeShippingBanner />
        </div>
        <header className={styles.header} ref={this.headerRef} key="header">
          {elements}
          <div className={styles.rightWrapper} onMouseLeave={() => this.handleItemHover(null)}>
            <div className={styles.iconWrapper}>
              {
                ITEMS.map(item => {
                  return (
                    <div className={this.state.currentItem === item ? styles.active : ''} key={item.key} onMouseOver={() => this.handleItemHover(item)}>
                      <Link className={cx(styles.icon, item.style)} to={item.to || 'Home'} onClick={ev => this.handleItemClick(item, ev)}>
                        {
                          item.key === 'cart' && <div className={styles.count}>{this.props.cartLineItemCount}</div>
                        }
                        {
                          item.content && <div>{item.content}</div>
                        }
                      </Link>
                      {
                        this.state.currentItem === item && item.floatPanelContent && <FloatPanel className={cx(item.panelStyle, this.state.fixTop && styles.fixTop)}>{item.floatPanelContent}</FloatPanel>
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default withAppState(DesktopHeader)
