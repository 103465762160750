
import React, { useState, useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'
import { withDrawer } from '../../common/Drawer/Drawer'
import HorizontalColumns from '../../common/List/HorizontalColumns'
import styles from './VideoFeed.module.css'
import cx from '../../../utils/className'
import FullScreenVideoPlayer from '../../common/Video/FullScreenVideoPlayer'


const PostImage = props => {
  const clickImage = () => {
    props.onClick(props.index)
  }
  return (
    <div className={cx(styles.postWrapper, styles.box)}>
      <Image
        src={props.src}
        middle
        lazy
        objectFit='cover'
        placeholder={{ width: 130, height: 232 }}
        className={styles.image}
        onClick={clickImage}
      />
    </div>
  )
}

export const VideoPlayerModal = withDrawer(FullScreenVideoPlayer, { fullScreen: true })


const VideoFeed = props => {
  const plugin = useContext(PluginContext)
  const { list } = props
  const hasImages = list.length > 0

  const [showImagesContent, setShowImagesContent] = useState(false)
  const [previewImageIndex, setPreviewImageIndex] = useState(0)


  const onCloseImagesContent = () => {
    setShowImagesContent(false)
  }


  const handleImageClick = (index) => {
    const { videoId } = list[index]
    plugin.$track.event(props.eventCategory, 'click_thumbnail', undefined, undefined, {
      id: videoId,
      index
    })
    setShowImagesContent(true)
    setPreviewImageIndex(index)
  }


  const shows = list.map((item) => {
    const props = {
      src: item.cutPoster,
      id: item.videoId
    }
    return props
  })

  if (!hasImages) {
    return null
  }

  return (
    <>
      <div className={styles.wrapper}>
        <HorizontalColumns
          className={styles.postImageGroup}
          childItems={shows}
          childIs={PostImage}
          childOptions={{
            onClick: handleImageClick
          }}
        />
      </div>
      {
        showImagesContent &&
        <VideoPlayerModal
          onClose={onCloseImagesContent}
          index={previewImageIndex}
          eventCategory={props.eventCategory}
          list={list.map(item => ({
            id: item.videoId,
            video: item.videoSrc
          }))}
        />
      }
    </>
  )
}

export default VideoFeed
