import React, { PureComponent }  from 'react'

import { animateScroll } from 'react-scroll'
import ProductBreadcrumbsNavi from '../Product/ProductBreadcrumbsNavi'
import ProductBasicInfoSection from '../Product/ProductBasicInfoSection'
import DesktopProductBasicInfoSection from '../Product/DesktopProductBasicInfoSection'
import CommitmentSection from '../Product/CommitmentSection'
import GirlsShowSection from '../Product/GirlsShowSection'
import ProductCommentSection from '../Product/ProductCommentSection'
import ShowAndReviewSection from '../Product/ShowAndReviewSection'
import VideoFeed from '../../common/Video/VideoFeed'
import { DescriptionModal } from '../Product/ProductDescription'
import { SizeChartModal } from '../Product/ProductSizeChartSection'
import ProductFooterBarWithProgress from '../Product/ProductFooterBarWithProgress'
import DescriptionSection from '../Product/DescriptionSection'
import TrafficDistributionSection from '../Product/TrafficDistributionSection'
import ProductRecommendTabList from '../ProductRecommendTabList/ProductRecommendTabList'
import ProductRecommend from '../ProductRecommendTabList/ProductRecommend'
import PictureDescription from '../PictureDescription/PictureDescription'
import ProductRelatedSearches from '../Product/ProductRelatedSearches'
import ProductGallery from '../ProductGallery/ProductGallery'
import withQuickAdd from '../../../hooks/withQuickAdd'
import ShopTheStyle from '../Product/ShopTheStyle'
import WishButtonContainer, { WISH_BUTTON_TYPE } from '../../../containers/User/WishButtonContainer'
import ProductSection from '../Product/ProductSection'
import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'

import { withPlugin } from '@flamingo_tech/funkgo'
import withBackToTop from '../../../hooks/withBackToTop'
import { OutApp } from '../../../hooks/InApp'
import { getVariantsByVariantOptions } from '../../../utils/Store/productUtils'

import styles from './ProductPage.module.css'
import cx from '../../../utils/className'

class ProductPage extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      curImages: [],
      curImageIndex: null,
      showDescModal: false,
      selectedVariantInfo: {},
      showSizeChartModal: false,
      showAddedToCartAnimation: false
    }

    this.showAndReviewSectionRef = React.createRef()
  }

  _isMounted = false

  setStateIfMounted(state) {
    if (this._isMounted) {
      this.setState(state)
    }
  }


  componentDidMount(){
    this._isMounted = true

    this.props.$store.subscribeCart(this.handleCartChange)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentVariantInfo && this.props.currentVariantInfo) {
      this.setState({
        selectedVariantInfo: this.props.currentVariantInfo || {}
      }, () => {
        this.getCurImagesByOption(this.props.currentVariantInfo, true)
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.props.$store.unsubscribeCart(this.handleCartChange)
  }

  handleCartChange = params => {
    const { product } = this.props

    if (params.cart &&  params.cart.itemQuantity > 0 && params.cartOperation.add) {
      this.setStateIfMounted({
        showAddedToCartAnimation: true
      })

      const isDesktop = this.props.$detector.isDesktop()
      if (product.id === params.cart.currentAddedProductId && !isDesktop) {
        setTimeout(() => {
          if (this.props.productRecommendRef && this.props.productRecommendRef.current) {
            const journeyElement = document.getElementById('download-banner')
            const pageYOffset = window.pageYOffset
            const recommendOffset = this.props.productRecommendRef.current.offsetTop - 44 + (journeyElement ? 76 : 0)

            if (pageYOffset < recommendOffset) {
              animateScroll.scrollTo(recommendOffset, {
                duration: 0,
                smooth: 'linear'
              })
            }
          }
        }, 1000)
      }

      clearTimeout(this.shakeTimer)

      this.shakeTimer = setTimeout(() => {
        this.setStateIfMounted({
          showAddedToCartAnimation: false
        })
      }, 1000)
    }
  }

  showDescModal = () => {
    const { product, $track } = this.props

    $track.event('ProductPage', 'click_description', product.handle)
    this.setStateIfMounted({
      showDescModal: true
    })
  }

  closeDescModal = () => {
    this.setStateIfMounted({
      showDescModal: false
    })
  }

  showSizeChartModal = () => {
    const { product, $track } = this.props

    $track.event('ProductPage', 'click_size_chart', product.handle)
    this.setStateIfMounted({
      showSizeChartModal: true
    })
  }

  closeSizeChartModal = () => {
    this.setStateIfMounted({
      showSizeChartModal: false
    })
  }

  handleSpecSelectionConfirm = ({ selectedVariant, selectedQuantity, triggerRef }) => {
    setTimeout(() => {
      const cartCount = this.props.cartLineItemCount

      if (cartCount < 1) {
        this.handleCartClick()
      }
    })

    return this.props.onAddCartItem({
      variant: selectedVariant,
      quantity: selectedQuantity,
      triggerRef
    })
  }

  generateCartInfo = () => {
    const { product } = this.props
    const cart = {
      selectedVariant: null,
      selectedOptions: this.state.selectedVariantInfo,
      selectedQuantity: 1
    }

    if (Object.keys(this.state.selectedVariantInfo).length === product.options.length) {
      const variants = getVariantsByVariantOptions(this.state.selectedVariantInfo, product.variations)

      cart.selectedVariant = variants[0]
    }
    return cart
  }

  generateVariantWithoutChosen = (selectedVariants, variants) => {
    return variants.filter(variant => {
      return !selectedVariants[variant.name]
    })[0]
  }


  handleAddToCartClick = ({ triggerRef }) => {
    // directly add to cart without open modal when there is default variant
    const { product, comment } = this.props

    if (product.availableForSale) {
      if (this.state.selectedVariantInfo) {
        const selectedVariants = this.state.selectedVariantInfo
        const cart = this.generateCartInfo()
        const isDesktop = this.props.$detector.isDesktop()

        if (cart.selectedVariant) {
          return this.handleSpecSelectionConfirm({
            ...cart,
            triggerRef
          })
        } else {
          if (isDesktop) {
            this.props.$toastWarning({
              content: this.props.$i18n.transl('core.product.chooseOption', {
                option: this.generateVariantWithoutChosen(selectedVariants, this.props.product.options).displayName
              }),
              dialogId: 'product-add'
            })
          } else {
            this.props.onQuickAdd({
              quickAddInfo: {
                product,
                comment,
                selectedVariantInfo: selectedVariants,
                renderLink: () => { },
                curImageIndex: this.state.curImageIndex
              },
              refInfo: {
                triggerRef
              }
            })
          }
          return Promise.resolve()
        }
      }
    }
  }

  handleCartClick = () => {
    this.props.onCartClick()
  }

  /* --------------------------------------- */

  switchRender(defaultElements, propRender, renderArgs = []) {
    if (typeof propRender === 'function') {
      return propRender(...renderArgs)
    }

    return defaultElements
  }

  onOptionSelectedChanged = (data) => {
    this.setStateIfMounted({
      selectedVariantInfo: data
    })
    this.getCurImagesByOption(data)
  }

  getSortedProductImages = (images, options) => {
    const tagImages = []
    options.forEach((option) => {
      if (option.name === 'Color') {
        option.values.forEach(opt => {
          images.forEach(image => {
            const { tag } = image
            if (tag && tag.optionValue && tag.optionValue === opt) {
              tagImages.push(image)
            }
          })
        })
      }
    })

    // filter untagImages
    const untagImages = images.filter(image => tagImages.indexOf(image) <= -1)

    return [...tagImages, ...untagImages]
  }

  getCurImagesByOption = (currentVariants = {}, force) => {
    let imgs = []
    const { images = [], options = [] } = this.props.product || {}
    const hasSelected = Object.keys(currentVariants).length > 0

    const selectedOption = hasSelected ? currentVariants.Color : this.state.selectedVariantInfo.Color

    if (currentVariants.Color  === this.state.selectedVariantInfo.Color  && !force) {
      return
    }

    imgs = this.getSortedProductImages(images, options)
    const lastImage = imgs.pop()
    imgs = lastImage && lastImage.tag.optionValue && lastImage.tag.optionValue === 'BrandImage' ? [lastImage, ...imgs] : [...imgs, lastImage]

    for (let i = 0; i < imgs.length; i++) {
      const { tag } = imgs[i]
      if (tag && tag.optionValue && tag.optionValue === selectedOption) {
        this.setStateIfMounted({
          curImageIndex: i
        })
        break
      }
    }

    this.setStateIfMounted({
      curImages: imgs
    })
  }

  hasOneSelectedVariant = (options) => {

    return Object.keys(this.state.selectedVariantInfo).length === options.length
  }

  toCartPage = () => {
    this.props.$track.event('ProductDetail', 'open_cart', 'productFooter')
    this.props.$router.navigate({
      name: 'Cart'
    })
  }

  handleCheckout = (...arg) => {
    this.props.$track.event('Cart', 'click_checkout', 'productFooter')
    return this.props.onCheckout({ ...arg, isSubmitAll: true })
  }

  renderWishButton = ({ className = '' } = {}) => {
    return (
      <WishButtonContainer
        productId={this.props.product.id}
        handle={this.props.product.handle}
        selectedVariants={this.state.selectedVariantInfo}
        wishButtonType={WISH_BUTTON_TYPE.NO_BACKGROUND}
        className={className}
      />
    )
  }

  render() {
    const {
      product,
      comment,
      breadcrumbs,
      commentPageNo,
      relatedTags = []
    } = this.props

    const curSelectedVariants = getVariantsByVariantOptions(this.state.selectedVariantInfo, product.variations)
    const variantInfo = this.hasOneSelectedVariant(product.options) ? curSelectedVariants[0] : product.mainVariation

    const {
      sizeChart = {},
      description = [],
      feature = {}
    } = product.meta || {}

    const { graphicDescription = [] } = feature

    const {
      category,
      sizeChartTemplate,
      flamingReels
    } = product

    const basicInfoSectionProps = {
      product: product,
      comment: comment,
      variantInfo: variantInfo,
      curImageIndex: this.state.curImageIndex,
      curImages: this.state.curImages.length > 0 ? this.state.curImages : product.images,
      selectedVariants: this.state.selectedVariantInfo,
      onOptionSelectedChanged: this.onOptionSelectedChanged,
      renderPromotionBar: this.props.renderPromotionBar,
      onCommentsClick: this.handleCommentsClick,
      showSizeChartModal: this.showSizeChartModal,
      renderSizeLink: true,
      shopTheStyleData: this.props.shopTheStyleData,
      flexible: true
    }

    const isDesktop = this.props.$detector.isDesktop()

    const BasicInfoSection = isDesktop ? DesktopProductBasicInfoSection : ProductBasicInfoSection

    return (
      <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
        <OutApp>
          {
            breadcrumbs && isDesktop && (
              <ProductBreadcrumbsNavi
                product={product}
                breadcrumbs={breadcrumbs}
              />
            )
          }
        </OutApp>

        {
          this.switchRender(
            <BasicInfoSection
              {...basicInfoSectionProps}
              sizeChartTemplate={sizeChartTemplate}
              renderWishButton={isDesktop && this.renderWishButton}
              onAddToCartClick={this.handleAddToCartClick}
              onOpenReviewsContent={this.showAndReviewSectionRef.current}
            />,
            this.props.renderBasicInfoSection,
            [{
              ...this.props,
              ...basicInfoSectionProps,
              renderPrice: this.props.renderPrice,
            }]
          )
        }

        {
          this.switchRender(
            null,
            this.props.renderTopSection
          )
        }

        {
          isDesktop ?
          <>
            <GirlsShowSection
              {...this.props.post}
              title={product.title}
              handle={product.handle}
              onReachEnd={this.props.onPostsReachEnd}
            />
            <ProductCommentSection
              product={product}
              comment={comment}
              commentPageNo={commentPageNo}
              onPageChange={this.props.onCommentPageChange}
              onCommentBySort={this.props.onCommentBySort}
              onAddToCartClick={this.handleAddToCartClick}
              ref={this.props.productCommentSectionRef}
              isFetchingComments={this.props.isFetchingComments}
            />
          </>
          :
          <>
            {
              flamingReels.length > 0 &&
              <ImpressionObserver eventName='Product:FlamingReels' eventId='Product:FlamingReels'>

                <ProductSection>
                    <div className={styles.header}>
                      <h2 className={styles.title}>{this.props.$i18n.transl('core.product.flamingReels')}</h2>
                    </div>
                    <VideoFeed className={styles.content} list={flamingReels} eventCategory='FlamingReelsVideo'/>
                </ProductSection>
              </ImpressionObserver>
            }

            <ShowAndReviewSection
              {...this.props.post}
              product={product}
              title={product.title}
              handle={product.handle}
              comment={comment}
              onCommentsReachEnd={this.props.onCommentsReachEnd}
              onPostsReachEnd={this.props.onPostsReachEnd}
              onCommentBySort={this.props.onCommentBySort}
              onAddToCartClick={this.handleAddToCartClick}
              ref={this.showAndReviewSectionRef}
              isFetchingComments={this.props.isFetchingComments}
              closeCommentPage={this.props.closeCommentPage}
            />
            <ShopTheStyle productHandle={product.handle} productCategory={product.category} shopTheStyleData={this.props.shopTheStyleData} />
          </>

        }

        {
          !isDesktop &&
          <DescriptionSection
            product={product}
            showSizeChartModal={this.showSizeChartModal}
            showDescModal={this.showDescModal}
            $i18n={this.props.$i18n}
            $track={this.props.$track}
          />
        }

        {
          !isDesktop &&
          this.switchRender(
            <CommitmentSection />,
            this.props.renderCommitment
          )
        }
        {
          !isDesktop && <PictureDescription graphicDescription={graphicDescription} />
        }
        {
          isDesktop && (
            <ShopTheStyle productHandle={product.handle} productCategory={product.category} isDesktop={true} shopTheStyleData={this.props.shopTheStyleData} />
          )
        }
        <TrafficDistributionSection trafficDistributionMeta={this.props.trafficDistributionMeta} productCategory={product.category}/>

        {
          !isDesktop ?
          <div className={styles.youMayAlsoLike} ref={this.props.productRecommendRef}>
            <ProductRecommendTabList
              collectionHandle={this.props.collectionHandle}
              isESCollection={this.props.isESCollection}
              productHandle={product.handle}
              productCategory={product.category}
              relatedTags={relatedTags}
            />
          </div> :
          <>
            <div className={styles.youMayAlsoLike} ref={this.props.productRecommendRef}>
              <ProductRecommend collectionHandle={this.props.collectionHandle} isESCollection={this.props.isESCollection} productHandle={product.handle}  productCategory={product.category} />
            </div>
            <ProductGallery
              galleryTitle={this.props.$i18n.transl('core.product.topPicksForYou')}
              eventCategory='TopPicksForYou'
              fetchFn='fetchPicksData'
              productHandle={product.handle}
              productCategory={product.category}
            />
            <ProductGallery
              galleryTitle={this.props.$i18n.transl('core.product.oftenBoughtWith')}
              eventCategory='OftenBoughtWith' fetchFn='fetchBoughtData'
              productHandle={product.handle}
              productCategory={product.category}
            />
            <ProductRelatedSearches
              productHandle={product.handle}
              productCategory={product.category}/>
          </>
        }

        {
          !isDesktop &&
          <div className={styles.fixedBottomBar}>
            {
              this.props.renderBottomBar
            }

            {
              this.switchRender(
                <ProductFooterBarWithProgress
                  {...product}
                  cartLineItemCount={this.props.cartLineItemCount}
                  onCheckout={this.handleCheckout}
                  showAddedToCartAnimation={this.state.showAddedToCartAnimation}
                  selectedVariants={this.state.selectedVariantInfo}
                  onCartClick={this.handleCartClick}
                  onAddToCartClick={this.handleAddToCartClick}
                  handleToCart={this.toCartPage}
                  showCartButton={true}
                  renderWishButton={this.renderWishButton}
                />,
                this.props.renderFooterBar,
                [{
                  handleCartClick: this.handleCartClick,
                  handleAddToCartClick: this.handleAddToCartClick,
                }]
              )
            }
          </div>
        }

        {
          this.state.showSizeChartModal &&
          <SizeChartModal
            $i18n={this.props.$i18n}
            productTitle={product.title}
            sizeChart={sizeChart}
            sizeChartTemplate={sizeChartTemplate}
            feature={feature}
            onClose={this.closeSizeChartModal}
            className={cx(styles.sizeChartPadding, isDesktop && styles.isDesktop)}
          />
        }

        {
          this.state.showDescModal &&
          <DescriptionModal
            $i18n={this.props.$i18n}
            hide={!this.state.showDescModal}
            productType={category}
            descList={description}
            onClose={this.closeDescModal}
          />
        }
      </div>
    )
  }
}

export default withQuickAdd(withBackToTop(withPlugin(ProductPage)))
