import React, { useEffect, useRef } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import EventsModal from '../../common/EventsModal/EventsModal'
import { STATIC_PAGE_HANDLES } from '../../../containers/Common/StaticModalContainer'
import Button from '../../common/Button/Button'
import CouponCardBox from './CouponCardBox'
import { getNextNinetyDays } from '../../../utils/dateUtils'
import cx from '../../../utils/className'
import { rem as px2rem } from '../../../utils/remUtils'

import styles from './WheelOverlayMobileCard.module.css'
const COUPON_CARD_THEME_COLOR = '#FFEACD'

const WheelOverlayMobileCard = withPlugin(props => {
  const { Trans } = props.$i18n
  const inputRef = useRef()
  const { locale } = props.$site.getSiteInfo()

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const handleClick = e => {
    props.handleSubmit(e)
    if (!props.email) {
      inputRef.current.focus()
    }
  }

  const renderCouponInfo = (coupon) => {
    const { couponTitle, couponSubtitle } = coupon

    return (
      <div className={styles.couponInfo}>
        <div className={styles.couponTitle}>{couponTitle}</div>
        <div className={styles.couponSubtitle}>{couponSubtitle}</div>
      </div>
    )
  }

  const renderCouponDesc = (coupon) => {
    const { effectiveDay, clientStartDate, clientEndDate, rule } = coupon
    const cartCouponsDate = getNextNinetyDays(Date.now())
    const now = new Date()

    const couponDate =  (
      (clientStartDate || clientEndDate)
      ? `${new Date(clientStartDate || now).toLocaleDateString()} ~ ${new Date(clientEndDate || now).toLocaleDateString()}`
      : (
        effectiveDay > 0
        ? (effectiveDay > 1 ? props.$i18n.transl('core.promotion.validDays', { days: effectiveDay }) : props.$i18n.transl('core.promotion.validDay', { days: effectiveDay }))
        : cartCouponsDate
      )
    )

    return (
      <div className={styles.couponDesc}>
        <div className={cx(styles.couponDate, effectiveDay > 0 && !clientEndDate && styles.highlighted)}>- {couponDate}</div>
        <div className={styles.couponRule}>- {rule || props.$i18n.transl('core.promotion.wheel.applyToAll')}</div>
      </div>
    )
  }

  if (props.coupons.length === 0) {
    return null
  }

  const style = {}

  if (props.coupons.length > 2) {
    style.maxHeight = px2rem(210)
    style.overflowY = 'auto'
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgGroup}>
        <div></div>
        <div className={styles.bgTitle}>SPECIAL OFFER</div>
      </div>
      <div className={styles.closeBtn} onClick={props.onClose}></div>
      <section className={styles.panel}>
        <form className={styles.controller} onSubmit={props.handleSubmit}>
          {
            ['en_US', 'de_DE'].indexOf(locale) > -1 && <div className={styles.header}>{props.$i18n.transl('core.promotion.wheel.claimYourCoupons')}</div>
          }
          <div className={styles.couponContainer} style={style}>
            {
              props.coupons.map((coupon, index) => (
                <CouponCardBox
                  key={index}
                  renderTopContent={() => renderCouponInfo(coupon)}
                  renderBottomContent={() => renderCouponDesc(coupon)}
                  className={styles.couponBox}
                  backgroundColor={COUPON_CARD_THEME_COLOR}
                  circleBackgroundColor="#ED4940"
                />
              ))
            }
          </div>
          <input
            ref={inputRef}
            type="email"
            className={styles.mainInput}
            value={props.email}

            placeholder={props.$i18n.transl('core.promotion.inputEmail')}
            onChange={props.onChange}
          />
          <div className={styles.privacyWrapper}>
            <Trans i18nKey='terms.agreeWithPrivacy'>
              By registering, you agree to our<EventsModal className={styles.underline} handle={STATIC_PAGE_HANDLES.PRIVACY_POLICY}> Privacy Policy </EventsModal>
            </Trans>
          </div>
          <Button
            className={styles.submitBtn}
            disabled={props.disabled}
            onClick={handleClick}>
            {props.$i18n.transl('core.promotion.wheel.takeMeDiscount')}
          </Button>
        </form>
      </section>
    </div>
  )
})

export default WheelOverlayMobileCard