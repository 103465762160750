import React, { Component } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import queryString from 'query-string'

import UnpaidOrderContainer from './EntryContainers/UnpaidOrderContainer'
import UnpaidCartContainer from './EntryContainers/UnpaidCartContainer'
import WelcomeModalContainer from './EntryContainers/WelcomeModalContainer' // 大转盘


class MobileEntryFlowContainer extends Component {
  hidePromoStorage = this.props.$storage.create('hide_promo', { strategy: 'SESSION' }) // MobileEntryFlowContainer 是否初始化
  mobileEntryFlowContainerLoadedStorage = this.props.$storage.create('m_e_f_loaded', { strategy: 'SESSION' }) // MobileEntryFlowContainer 是否初始化
  sourceLocationStorage = this.props.$storage.create('source_location', { strategy: 'SESSION'})

  state = {
    stepIndex: 0,
    hideWheel: false,
    isMounted: this.mobileEntryFlowContainerLoadedStorage.getItem(false),
    isAbandonCartForAds: false
  }

  componentDidMount() {
    const sourceLocation = queryString.parseUrl(this.sourceLocationStorage.getItem(window.location.href))

    if (sourceLocation.query && sourceLocation.query.hide_wheel) {
      this.setState({
        hideWheel: true
      })
    }

    if (sourceLocation.query && sourceLocation.query.ac_ads) {
      this.setState({
        isAbandonCartForAds: true
      })
    }
  }

  setMounted = () => {
    this.setState({
      isMounted: true
    })

    this.mobileEntryFlowContainerLoadedStorage.setItem(true)
  }

  render() {
    const hidePromo = this.hidePromoStorage.getItem()

    if (hidePromo) {
      return null
    }

    switch (this.state.stepIndex) {
      case 0: // 未订单支付弹窗， 随时查询
        return (
          <UnpaidOrderContainer
            key='unpaid_order'
            toNext={() => this.setState(({ stepIndex }) => ({ stepIndex: stepIndex + 1 }))}
          />)
      case 1:
        return (
          <UnpaidCartContainer
            key='unpaid_cart'
            toNext={() => this.setState(({ stepIndex }) => ({ stepIndex: stepIndex + 1 }))}
            mounted={this.state.isMounted}
            entryStorage={this.mobileEntryFlowContainerLoadedStorage}
            isForAds={this.state.isAbandonCartForAds}
          />
        )
      case 2: // 大转盘，初始化查询
        return (
          <WelcomeModalContainer
            key='welcome_modal'
            hideWheel={this.state.hideWheel}
            footHintHeight={this.props.footHintHeight}
            mounted={this.state.isMounted}
            setMounted={this.setMounted}
            entryStorage={this.mobileEntryFlowContainerLoadedStorage}
          />
        )
      default:
        return null
    }
  }
}

export default withPlugin(MobileEntryFlowContainer)
