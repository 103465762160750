import React, { useEffect } from 'react'
import Button from '../../common/Button/Button'
import { withModal } from '../../common/Modal/Modal'


import styles from './WheelOverlayWinBackCard.module.css'

const EVENT_CATEGORY = 'WheelWinBack'

const WheelOverlayWinBackCard = withModal(props => {

  useEffect(() => {
    props.$track.event(EVENT_CATEGORY, 'view')
  }, [props.$track])

  const handleOK = () => {
    props.$track.event(EVENT_CATEGORY, 'win_success')
    props.onClose(true)
  }

  const handleCancel = () => {
    props.$track.event(EVENT_CATEGORY, 'win_fail')
    props.onClose(false)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{props.$i18n.transl('core.promotion.wheel.winback.title')}</div>
      <div className={styles.desc}>{props.$i18n.transl('core.promotion.wheel.winback.subtitle')}</div>
      <div className={styles.buttonGroup}>
        <Button
          className={styles.okBtn}
          disabled={props.disabled}
          onClick={handleOK}>
          {props.$i18n.transl('core.promotion.wheel.winback.okButton')}
        </Button>
        <Button
          className={styles.cancelBtn}
          disabled={props.disabled}
          onClick={handleCancel}>
          {props.$i18n.transl('core.promotion.wheel.winback.cancelButton', { couponName: props.welcomeOffer.couponTitle })}
        </Button>
      </div>
    </div>
  )
}, { disableCoverClick: true })


export default WheelOverlayWinBackCard