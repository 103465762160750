import React, { PureComponent } from 'react'

import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'

import withCartAndPlugins from '../../hooks/withCartAndPlugins'

import withAppState from '../../hooks/withAppState'
import Shell from '../Shell/PageShellContainer'

import ProductPage from '../../components/Store/ProductPage/ProductPage'
import FlashPromotionBar from '../../components/Store/Product/FlashPromotionBar'
import PromotionBar from '../../components/Store/Product/PromotionBar'
import ProductPromotionBar from '../../components/Store/Product/ProductPromotionBar'
import ProductDetailSkeleton from '../../components/Skeleton/ProductDetailSkeleton'

import WishButtonContainer from '../../containers/User/WishButtonContainer'

import ProductService from '../../services/ProductService'
import RecentViewService from '../../services/RecentViewService'
import CommentService from '../../services/CommentService'
import PostService from '../../services/PostService'
import StoreService from '../../services/StoreService'
import { DynamicProductVariant } from '../../npm/FunkGoModel/ProductModel'

import { RECOMMENDATIONS_COLLECTION_HANDLE, RECOMMENDATIONS_ES_COLLECTION_HANDLE, getProductDetailTrackChannel } from '../../utils/Store/productUtils'
import * as seoUtils from '../../utils/seoUtils'
import { rem as px2rem } from '../../utils/remUtils'
import { extractSearch } from '../../utils/breadcrumbUtils'
import { formatTitle, getLink } from '../../utils/collectionUtils'
import { setDefaultSelectedOptionValues } from '../../utils/Store/productUtils'
import { getShippingInfo } from '../../utils/seoUtils'

class ProductDetailContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.productCommentSectionRef = React.createRef()
  }

  static Shell = Shell
  static shellProps = ({ $detector, $router }) => {

    return {
      promotion: true,
      hideGlobalCart: true,
      promotionProps: {
        displayBottomActionBar: true
      },
      floatingHeader: true,
      displayCart: !$detector.isApp(),
      renderButton: $detector.isApp()
          ? () => <WishButtonContainer handle={$router.match.params.handle} />
          : null,
      supportDesktop: true,
      showShippingBar: true,
      showDownloadBanner: true,
      downloadPage: 'product',
      footHintHeight: 60,
      WTAfootHintHeight: 60
    }
  }

  static LoadingComponent = ProductDetailSkeleton

  _isMounted = false
  postService = new PostService(this.props.$http)
  commentService = new CommentService(this.props.$http)
  recentViewService = new RecentViewService(this.props.$http)
  productService = new ProductService(this.props.$http)
  storeService = new StoreService(this.props.$http)

  commentLimit = ProductDetailContainer.getCommentLimit({ $detector: this.props.$detector })

  state = {
    post: {
      posts: [],
      pageInfo: {}
    },
    product: null,
    comments: [],
    commentCount: this.props.data.comment && this.props.data.comment.count,
    couponUpdateTimes: 1,
    shopTheStyleData: null,
    commentPageNo: 1,
    isFetchingComments: false,
  }

  isFetchingPosts = false
  postPageNum = 2 // used by fetchPostsNextPage
  isCommentsFetched = false
  productRecommendRef = React.createRef()


  static fetchOpenGraphInfo({ data }, { $router, $site, $i18n }) {
    const { product, comment } = data

    if (product) {
      const breadcrumbs = []

      if (product.collection && product.collection.handle) {
        breadcrumbs.push({
          name: product.collection.title || 'Collection',
          link: $router.getFinalPath({
            name: 'Collection',
            params: {
              handle: product.collection.handle
            }
          })
        })
      }

      const site = $site.getSiteInfo()

      let optionObject = {}
      product.options.forEach(item => {
        optionObject[item.name] = item.values.join(', ')
      })
      const colorDesc = optionObject.Color ? ` Color Available: ${optionObject.Color}.` : ''
      const sizeDesc = optionObject.Size ? ` Size in: ${optionObject.Size}.` : ''
      const metaDescription = product.meta.description ? product.meta.description.reduce((total, cur) => {
        const str = `${cur.materialName}: ${cur.materialDisplayName}`
        return total ? total + `; ${str}` : str
      }, '') : ''
      const description = `Buy ${product.title} at ${site.fullSiteName}.${colorDesc}${sizeDesc} ${metaDescription}. ${getShippingInfo({ $site, $i18n })}`

      const jsonLD = [
        seoUtils.forBreadcrumb(breadcrumbs),
        seoUtils.forProduct(product, { comment, description })
      ]

      return {
        title: `${product.title} | ${site.fullSiteName}`,
        description,
        image: product.mainImage ? product.mainImage.src : null,
        jsonLD
      }
    } else {
      return {}
    }
  }

  static fetchProductData({ $http, $router, $detector }, handle, eventID=uuidv4()) {
    const productService = new ProductService($http)
    const commentService = new CommentService($http)

    const productReq = productService.fetchProduct(handle, eventID)

    const productCommentReq = commentService.fetchProductCommentQueue(handle, {
      limit: ProductDetailContainer.getCommentInitialLimit({ $detector })
    }).catch(() => CommentService.getFallbackPreviewCommentInfo())

    const isDesktop = $detector.isDesktop()

    return Promise.all([
      productReq,
      productCommentReq
    ]).then(([product, comment]) => {
      const collectionHandle = ProductDetailContainer.getRecommendCollection({ $router }).collectionHandle
      const isESCollection = ProductDetailContainer.getRecommendCollection({ $router }).isESCollection
      return Promise.resolve({
        product: isDesktop ? {
          ...product,
          images: (product.images || []).filter(item => item.type !== 'VIDEO') // pc always hide video resource
        } : product,
        comment,
        eventID,
        collectionHandle,
        isESCollection
      })
    })
  }

  static fetchPageData(plugins) {
    const handle = plugins.match.params.handle

    return ProductDetailContainer.fetchProductData(plugins, handle)
  }

  fetchPosts() {
    return this.postService.fetchProductRelatedPosts(this.props.match.params.handle, {
      needCount: true
    })
      .then(post => {
        this.postHasNextPage = post.pageInfo.hasNextPage
        this.setState({
          post
        })
      })
      .catch(() => ({}))
  }

  fetchPostsNextPage() {
    if (!this.isFetchingPosts && this.postHasNextPage) {
      this.isFetchingPosts = true
      this.postService.fetchProductRelatedPosts(this.props.match.params.handle, {
        pageNum: this.postPageNum,
        needCount: true
      }).then(data => {
        this.isFetchingPosts = false
        this.postHasNextPage = data.pageInfo.hasNextPage
        this.postPageNum += 1

        const { posts, pageInfo } = this.state.post

        this.setState({
          post: {
            pageInfo: Object.assign({}, data.pageInfo, { totalCount: data.pageInfo.totalCount || pageInfo.totalCount }),
            posts: [...(posts || []), ...data.posts]
          }
        })
      }).catch(() => {
        this.isFetchingPosts = false
      })
    }
  }

  fetchTrafficMetaInfo() {
    const trafficMetaStorage = this.props.$storage.create('_global_traffic_distribution', { strategy: 'SESSION' }) // client端 判断本地是否有cache
    const trafficDistributionMeta = trafficMetaStorage.getItem()
    let trafficMetaReq = Promise.resolve(trafficDistributionMeta)


    if (!trafficDistributionMeta) {
      trafficMetaReq = this.storeService.fetchTrafficDistributionMeta().catch(() => null)
    }

    return trafficMetaReq.then(trafficDistributionMeta => {
      const legacyImages = ['https://img.flamingo.shop/filters:strip_exif()/fit-in/1600x1600/p/show/99627a8242074fe981f8174685b4b800.jpg',
      'https://img.flamingo.shop/filters:strip_exif()/fit-in/1600x1600/p/show/e8adb4a97365433b9ad579e5e623ce23.jpeg'
    ]
      if (trafficDistributionMeta && trafficDistributionMeta.productDetail && legacyImages.indexOf(trafficDistributionMeta.productDetail.image) > -1) {
        this.props.$track.event('Product', 'traffic_data_error')
      }

      trafficMetaStorage.setItem(trafficDistributionMeta)

      this.setState({
        trafficDistributionMeta
      })
    })
  }

  initPageNum = 1
  commentPageNo = 1
  commentSortData = {} // 筛选数据

  closeCommentPage = () => {
    this.commentSortData = {}
    this.commentPageNo = this.initPageNum
    this.setState({ comments: [] })
  }

  fetchCommentByPage = pageNum => {

    this.setState({
      isFetchingComments: true
    })

    return this.commentService.fetchProductCommentQueue(this.props.match.params.handle, {
      limit: this.commentLimit,
      needCount: true,
      pageNum: pageNum - 1,
      ...this.commentSortData
    }).then(data => {
      this.isCommentsFetched = true
      this.setState({
        comments: data.comments || [],
        commentCount: data.count,
        isFetchingComments: false
      })
    }).catch(() => {
      this.setState({
        isFetchingComments: false
      })
    })
  }

  handleCommentsReachEnd = () => {
    if (this.state.commentCount && this.state.comments.length >= this.state.commentCount) {
      return
    }

    if (!this.state.isFetchingComments) {
      this.setState({
        isFetchingComments: true
      })

      this.commentService.fetchProductCommentQueue(this.props.match.params.handle, {
        limit: this.commentLimit,
        needCount: true,
        pageNum: this.commentPageNo - 1,
        ...this.commentSortData
      }).then(data => {
        this.commentPageNo++
        this.isCommentsFetched = true
        this.setState({
          comments: [...this.state.comments, ...(data.comments || [])],
          commentCount: data.count,
          isFetchingComments: false
        })
      }).catch(() => {
          this.setState({
          isFetchingComments: false
        })
      })
    }
  }

  fetchCommentBySort = ({ withPicture, starList, colorList, sizeList, sortType }) => {
    const isDesktop = this.props.$detector.isDesktop()
    this.commentSortData = {
      starList,
      colorList,
      sizeList,
      withPicture: withPicture?.[0] === true ? withPicture : undefined,
      sortType: sortType !== 1 ? sortType : undefined
    }

    if (isDesktop) {
      this.fetchCommentByPage(this.initPageNum)
      this.productCommentSectionRef.current(this.initPageNum)
    } else {
      let stagingComments = this.state.comments
      this.commentPageNo = this.initPageNum
      this.setState({ comments: [] }, () => {
        this.handleCommentsReachEnd(stagingComments)
      })
    }
  }

  getProductData() {
    return {
      ...this.props.data,
      product: this.state.product || this.props.data.product
    }
  }

  getProduct() {
    const productData = this.getProductData()
    return productData.product
  }

  addCartItem = (payload) => {
    const trackChannel = getProductDetailTrackChannel(this.props.location)
    return this.props.$addVariant({
      ...payload,
      trackChannel
    }).then(() => {
      const isDesktop = this.props.$detector.isDesktop()
      if (!isDesktop) {
        this.props.$store.startAtcParabola({
          startRef: payload.triggerRef
        })
      }

      if (this.props.onAddedToCart) {
        this.props.onAddedToCart()
      }
    }).catch(err => {
      if (err.errorCode === 300005 || err.errorCode === 300007 || err.errorCode === 300003) {
        this.props.$alert(err.message)
      } else {
        this.props.$toastError(err.message)
      }
    })
  }

  handleAddCartItem = ({ variant, quantity, triggerRef }) => {
    const product = this.getProduct()
    const payload = { variant, quantity, product, triggerRef }

    if (typeof this.props.onAddCartItem === 'function') {
      return this.props.onAddCartItem(payload)
    } else {
      return this.addCartItem(payload)
    }
  }

  handleCartClick = () => {
    this.props.$touchCart().catch(() => undefined)
  }

  renderPromotionBar = product => {
    const { meta } = product

    if (meta && meta.feature && meta.feature.promotionBar) {
      return (
        <ProductPromotionBar $i18n={this.props.$i18n} {...meta.feature.promotionBar} />
      )
    }

    const { msrp, price } = product.mainVariation || {}
    const hasFlashDiscount = !!(msrp && msrp > price && product.isFlashSale)

    if (hasFlashDiscount) {
      const isDesktop = this.props.$detector.isDesktop()
      const style = { width: px2rem(isDesktop ? 420 : 375) }
      return <div style={style}><FlashPromotionBar product={product} /></div>
    }

    const bestCoupon = this.props.$store
      ? this.props.$store.couponHub.pickBestForProduct(product)
      : undefined

    if (!bestCoupon) {
      return null
    }

    const { productDetail } = bestCoupon.meta.layout || {}
    if (productDetail && productDetail.is === 'PromotionBar') {
      return (
        <PromotionBar
          $i18n={this.props.$i18n}
          promotion={bestCoupon}
          {...productDetail.options}
        />
      )
    } else {
      return null
    }
  }

  handleCouponChanged = () => {
    this.setState({
      couponUpdateTimes: this.state.couponUpdateTimes + 1
    })
  }

  updateRecentViewProduct(product) {
    const currentSimpleProduct = {
      mainImage: product.mainImage,
      title: product.title,
      handle: product.handle
    }
    this.recentViewService.putNewProduct(currentSimpleProduct)
  }


  getVariant = () => {
    // 支持link后带variant(sku id)
    const query = queryString.parse(this.props.$router.location.search)
    const product = this.getProduct()
    let currentVariantInfo = setDefaultSelectedOptionValues(product)
    let currentVariation

    if (query.variant) {

      currentVariation = (product.variations.filter(item => item.id === query.variant && item.availableForSale)[0]) || null

      if (currentVariation) {
        currentVariation.selectedOptions.forEach(option => {
          currentVariantInfo[option.name] = option.value
        })
      }
    }

    return {
      currentVariation,
      currentVariantInfo
    }
  }

  componentDidMount() {
    this._isMounted = true

    this.fetchDynamicProduct()

    if (this.props.$router.history.action !== 'POP') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant"
      })
    }

    setTimeout(() => {
      this.fetchPosts()
      this.fetchTrafficMetaInfo()
      this.fetchShopTheStyle()

      const product = this.getProduct()

      const { currentVariation } = this.getVariant()

      const eventID = this.props.data.eventID || uuidv4()

      if (product) {
        this.props.$track.viewProduct(currentVariation || product.mainVariation, product, eventID)
        this.updateRecentViewProduct(product)
      }

      const couponHub = this.props.$store && this.props.$store.couponHub

      couponHub.subscribe(this.handleCouponChanged)

      // install klarna osm
      // this.props.$bridge && this.props.$bridge.installKlarnaOneSiteMessage()
    }, 200)
  }

  componentWillUnmount() {
    this._isMounted = false

    const couponHub = this.props.$store && this.props.$store.couponHub

    couponHub.unsubscribe(this.handleCouponChanged)
  }


  fetchDynamicProduct() {
    this.productService.fetchDynamicProduct(this.props.match.params.handle).then(data => {

      const { availableForSale, mainVariation, variationsMap } = data

      const product = this.getProduct()

      const { mainVariation: oldMainVariation, variations } = product

      const updatedVariations = variations.map(variation => ({
        ...variation,
        ...new DynamicProductVariant(variationsMap[variation.id])
      }))

      const updatedProduct = {
        ...product,
        availableForSale,
        variations: updatedVariations,
      }

      if (availableForSale && updatedVariations.filter(variation => variation.availableForSale).length === 0) {
        updatedProduct.availableForSale = false
      }

      if (oldMainVariation.id === mainVariation.id) {
        updatedProduct.mainVariation = Object.assign({}, oldMainVariation, new DynamicProductVariant(mainVariation))
      }

      if (!updatedProduct.availableForSale) {
        this.props.$track.event('Product', 'outOfStock', updatedProduct.handle)
      }

      this.setState({
        product: updatedProduct
      }).then(() => {
        const { currentVariantInfo } = this.getVariant()

        this.setState({
          currentVariantInfo
        })
      })
    }).catch(() => {
      const { currentVariantInfo } = this.getVariant()

      this.setState({
        currentVariantInfo
      })
    })
  }

  getBreadCrumbs() {
    let breadcrumbs

    if (this.props.breadcrumbs) {
      breadcrumbs = this.props.breadcrumbs
    } else {
      const search = extractSearch(this.props.location.search)

      if (search.routeName) {
        breadcrumbs = [{
          title: formatTitle(search.title) || search.routeName,
          link: {
            name: search.routeName,
            params: {
              handle: search.routeHandle
            }
          }
        }]
      }
    }

    if (Array.isArray(breadcrumbs)) {
      breadcrumbs = breadcrumbs.filter(breadcrumb => this.props.$router.validLink(breadcrumb.link))
    }

    if (!Array.isArray(breadcrumbs) || !breadcrumbs.length) {
      const product = this.getProduct()
      const defaultCollection = product.collection

      if (defaultCollection) {
        breadcrumbs = [{
          title: formatTitle(defaultCollection.title),
          link: getLink(defaultCollection)
        }]
      }
    }

    return breadcrumbs
  }

  static getRecommendCollection({ $router }) {
    let result = {}

    const location = $router.location

    if (location) {
      const query = queryString.parse(location.search)

      if (query[RECOMMENDATIONS_COLLECTION_HANDLE]) {
        result.collectionHandle = query[RECOMMENDATIONS_COLLECTION_HANDLE]
      } else if (query[RECOMMENDATIONS_ES_COLLECTION_HANDLE]) {
        result.collectionHandle = query[RECOMMENDATIONS_ES_COLLECTION_HANDLE]
        result.isESCollection = true
      }
    }

    // otherwise return empty, it will display the default recommend logic
    return result
  }

  static getCommentLimit({ $detector }) {
    const isDesktop = $detector.isDesktop()
    return isDesktop ? 4 : 10
  }

  static getCommentInitialLimit({ $detector }) {
    const isDesktop = $detector.isDesktop()
    return isDesktop ? 4 : 2
  }


  handlePostsReachEnd = () => {
    this.fetchPostsNextPage()
  }

  getCommentInfo = () => {
    const { comment = {} } = this.getProductData()
    let comments = []
    const isDesktop = this.props.$detector.isDesktop()
    if (isDesktop) {
      comments = this.isCommentsFetched ? this.state.comments : comment.comments
    } else {
      comments = this.state.comments
    }

    return {
      ...comment,
      comments,
      count: this.state.commentCount,
      firstComments: comment.comments,
      overallCount: comment.count,
      showCommentSection: comment.count > 0
    }
  }

  fetchShopTheStyle = () => {
    return this.productService.fetchShopTheStyle(this.props.match.params.handle)
    .then(res => {
      this.setState({
        shopTheStyleData: res
      })
    })
    .catch(() => {})
  }

  /* ---------------------------------------------- */

  render() {
    const {
      breadcrumb,
      data,
      ...restProps
    } = this.props

    const productData = this.getProductData()

    const props = {
      // some interfaces for other product related page to customize product page, pass to ProductPage directly
      ...restProps,

      ...productData,

      currentVariantInfo: this.state.currentVariantInfo,
      post: this.state.post,
      comment: this.getCommentInfo(),
      isFetchingComments: this.state.isFetchingComments,
      trafficDistributionMeta: this.state.trafficDistributionMeta,

      onAddCartItem: this.handleAddCartItem,
      onCartClick: this.handleCartClick,
      onPostsReachEnd: this.handlePostsReachEnd,
      onCommentPageChange: this.fetchCommentByPage,
      onCommentsReachEnd: this.handleCommentsReachEnd,
      onCommentBySort: this.fetchCommentBySort,

      renderPromotionBar: restProps.renderPromotionBar || this.renderPromotionBar,

      shopTheStyleData: this.state.shopTheStyleData,

      breadcrumbs: this.getBreadCrumbs(),
      onCheckout: this.props.$checkout,

      commentPageNo: this.state.commentPageNo,
      productCommentSectionRef: this.productCommentSectionRef,
      closeCommentPage: this.closeCommentPage,

      productRecommendRef: this.productRecommendRef
    }

    return (
      <ProductPage {...props} />
    )
  }
}

const ProductDetail = withAppState(withCartAndPlugins(ProductDetailContainer))

ProductDetail.fetchProductData = ProductDetailContainer.fetchProductData

export default ProductDetail