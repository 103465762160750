import React from 'react'
import { withModal } from '../Modal/Modal'

import styles from './LoadingPage.module.css'

const LoadingContent = (props) => {
  const Roller = () => (
    <div className={styles.loadingRoller}>
      <div></div>
    </div>
  )
  if (!props.message) {
    return (
      <Roller></Roller>
    )
  } else {
    return (
      <div className={styles.whiteWrapper}>
        <Roller></Roller>
        <p className={styles.message}>{props.message}</p>
      </div>
    )
  }
}

const Loading = withModal(LoadingContent, { shadow: true, maskClassName: styles.maskClassName })

const LoadingModal = props => {
  return <Loading onClose={() => null} {...props}/>
}


export default LoadingModal
