import {
  parseNullablePrice,
} from './utils/priceUtils.js'

import {
  pickMainVariation,
} from './utils/productUtils.js'


/* ---------------------------------------------------- */

const TYPE = {
  LEGACY: 'LEGACY',
  SHOPIFY: 'SHOPIFY',
  DEFAULT: 'DEFAULT',
}

/* ---------------------------------------------------- */

class ProductImage {
  constructor(data) {
    this.src = data.originalSrc || data.src
    this.title = data.title || data.altText || null
    this.tag = data.tag || {}
    this.type = (data.type || '').toUpperCase() // VIDEO only, default is image
    this.videoUrl = data.videoUrl
  }
}

/* ---------------------------------------------------- */

class ProductVariant {
  constructor(data) {

    this.id = data.id
    this.title = data.title
    this.sku = data.sku

    this.msrp = parseNullablePrice(data.msrp)
    this.price = data.price
    this.currencySymbol = data.currencySymbol
    this.currencyCode = data.currencyCode
    this.displayPrice = data.displayPrice
    this.displayMsrp = data.displayMsrp
    this.saveFee = data.saveFee
    this.displaySaveFee = data.displaySaveFee

    this.image = data.image ? new ProductImage(data.image) : null

    this.selectedOptions = data.selectedOptions

    this.availableForSale = data.availableForSale

  }
}

class DynamicProductVariant {
  constructor(data) {

    this.id = data.id

    this.msrp = parseNullablePrice(data.msrp)
    this.price = data.price

    this.displayPrice = data.displayPrice
    this.displayMsrp = data.displayMsrp

    this.availableForSale = data.availableForSale
  }
}

/* ---------------------------------------------------- */

class Product {
  constructor(data) {
    this.id = data.id

    this.handle = data.handle

    this.variations	= (data.variations || []).map(item => new ProductVariant(item))

    this.mainVariation = data.mainVariation
      ? new ProductVariant(data.mainVariation)
      : pickMainVariation({
        variations: this.variations
      })

    this.images = (data.images || []).map(item => new ProductImage(item))
    this.mainImage = data.mainImage
      ? new ProductImage(data.mainImage)
      : this.images[0] || null

    this.options = data.options || []
    this.colorSwatchList = data.colorSwatchList || []

    this.title = data.title
    this.description = data.description

    this.storeUrl = data.storeUrl

    this.vendor = data.vendor
    this.category = data.category
    this.tags = data.tags || []
    this.isNew = data.tags && data.tags.indexOf('New Arrival') > -1
    this.promotionActivities = data.promotionActivities
    this.isFlashSale = data.promotionActivities && data.promotionActivities.filter(i => i.name === 'FlashSale').length > 0
    this.soldOutPercent = data.soldOutPercent

    this.availableForSale = Boolean(data.availableForSale)

    this.meta = data.meta1 || {}
    this.sizeChartTemplate = data.sizeChartTemplate
    this.sizeGuideMeta = data.sizeGuideMeta
    this.models = data.models || []
    this.flamingReels = data.flamingReels || []

    this.collection = data.collection

  }
}

class ProductFeed {
  constructor(data) {
    this.id = data.id

    this.handle = data.handle

    this.mainVariation = data.mainVariation
      ? new ProductVariant(data.mainVariation)
      : pickMainVariation({
        variations: this.variations
      })

    this.mainImage = data.mainImage
      ? new ProductImage(data.mainImage)
      : this.images[0] || null


    this.title = data.title
    this.category = data.category
    this.colorSwatchList = data.colorSwatchList || []
    this.tags = data.tags || []
    this.promotions = data.promotions
    this.isFlashSale = data.promotions && data.promotions.filter(i => i === 'FlashSale').length > 0
    this.soldOutPercent = data.soldOutPercent
    this.promotionBar = data.promotionBar
    this.promotionTag = data.promotionTag
  }
}

class ColorSwatchItem {
  constructor(data) {
    this.color = data.color
    this.optionValue = data.optionValue
    this.productImageUrl = data.originProductImageUrl || data.productImageUrl
    this.colorImageLink = data.originColorImageLink || data.colorImageLink
    this.colorImageUrl = data.originColorImageUrl || data.colorImageUrl
  }
}

class ESProductFeed {
  constructor(data) {
    this.id = data.id

    this.handle = data.handle

    this.mainVariation = {
      msrp: parseNullablePrice(data.originPrice),
      price: data.price,
      currencySymbol: data.symbol,
      displayPrice: data.displayPrice,
      displayMsrp: data.displayOriginPrice,
      saveFee: data.saveFee,
      displaySaveFee: data.displaySaveFee,
    }

    this.mainImage = {
      src: data.originImg || data.img
    }

    this.title = data.title
    this.category = data.category
    this.tags = data.tags || []
    this.isNew = data.tags && data.tags.indexOf('New Arrival') > -1
    this.promotions = data.promotions
    this.isFlashSale = data.promotions && data.promotions.filter(i => i === 'FlashSale').length > 0
    this.soldOutPercent = data.soldOutPercent
    this.colorSwatchList = (data.colorSwatchList || []).map(item => new ColorSwatchItem(item))
    this.promotionBar = data.promotionBar
    this.promotionTag = data.promotionTag
  }
}


export { TYPE, ProductVariant, DynamicProductVariant, ProductFeed, ESProductFeed }

export default Product
