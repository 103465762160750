import React, { PureComponent } from 'react'
import InView from '../InView/InView'
import NewProgressBar from '../ProgressBar/NewProgressBar'
import { withPlugin } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'
import styles from './VideoPlayer.module.css'

const EVENT_CATEGORY = 'VideoPlayer'

let hasTracked = false

class VideoPlayer extends PureComponent {
  state = {
    isPlaying: false,
    muted: true,
    progress: 0,
    canPlay: false,
    style: {}
  }

  video = React.createRef()
  _isPlayFinished = false
  _manualPaused = false
  _needTriggerPlay = false
  duration = 0
  viewTime = 0
  watchTime = 0
  bufferingStartTime = 0
  totalBufferingTime = 0



  handleClickVideo = e => {
    e.stopPropagation()

    if (this.state.isPlaying) {
      this.setState({
        isPlaying: false
      })

      this._manualPaused = true

      this.video.current.pause()

    } else {
      this._manualPaused = false

      if (this._isPlayFinished) {
        this.trackView()
      }

      this._isPlayFinished = false // restarted

      this.setState({
        isPlaying: true
      })

      this.video.current.play()
    }
  }

  handleToggleMute = ev => {
    ev.stopPropagation()

    this.setState({
      muted: !this.state.muted
    })

    const video = this.video.current

    video.muted = !this.state.muted
  }


  handleInViewChange = inView => {
    if (inView) {
      this.trackView()
      if (!this._isPlayFinished && !this.state.isPlaying && !this._manualPaused) {
        if (this.state.canPlay) {
          this._needTriggerPlay = false
          this.setState({
            isPlaying: true
          })
          this.video.current.play()

        } else {
          this._needTriggerPlay = true
        }
      }
      return
    }

    if (!inView && this.state.isPlaying) {
      this._needTriggerPlay = false
      this.trackWatchTimeAndBufferingRatio()
      this.setState({
        isPlaying: false
      })

      this.video.current.pause()

      return
    }
  }

  handleTimeUpdate = () => {
    const video = this.video.current

    const { currentTime, duration } = video

    const progress = (isNaN(currentTime) ? 0 : currentTime) / (isNaN(duration) ? 1 : duration)

    if (!isNaN(duration) && duration > 0) {
      if (progress >= 0.50 && progress < 0.75 && !(this.lastProgress >= 0.50 && this.lastProgress < 0.75)) {
        this.trackEvent({ action: 'midpoint' })
      } else if (progress >= 0.90 && !(this.lastProgress >= 0.90)) {
        this.trackEvent({ action: 'complete' })
      }

      this.lastProgress = progress
      this.watchTime = currentTime
      this.duration =  duration
    }

    this.setState({
      progress
    })
  }

  trackEvent = ({
    action,
    label
  }) => {
    this.props.$track.event(this.props.eventCategory ||  EVENT_CATEGORY, action, label, undefined, {
      id: this.props.src
    })
  }

  handleError = (ev) => {
    const errorMessage = ev.target.error ? ev.target.error.message : 'load_error'

    this.trackEvent({
      action: 'error',
      label: `${errorMessage}_${this.video.current && this.video.current.canPlayType ? this.video.current.canPlayType('video/mp4; codecs="avc1"') : ''}`,
    })
  }

  handleCanPlay = () => {
    this.setState({
      canPlay: true
    })

    if (this._needTriggerPlay && !this._isPlayFinished && !this.state.isPlaying && !this._manualPaused) {
      this.video.current.play()
      this.setState({
        isPlaying: true
      })
    }

    if (!hasTracked) {
      hasTracked = true
      this.trackEvent({
        action: 'timeToFirstFrame',
        label: new Date() - this.viewTime
      })
    }
  }

  handleWaiting = () => {
    this.bufferingStartTime = new Date()
  }

  trackView = () => {
    this.trackEvent({ action: 'view', label: this.state.canPlay })
  }

  trackWatchTimeAndBufferingRatio = () => {
    if (this.watchTime) {
      this.trackEvent({
        action: 'viewTime',
        label: this.watchTime.toFixed(2)
      })
    }

    this.watchTime = 0

    if (this.totalBufferingTime) {

      this.trackEvent({
        action: 'bufferingTime',
        label: this.totalBufferingTime
      })


      if (this.duration) {
        const ratio = (this.totalBufferingTime / ((this.duration || 1) * 1000)).toFixed(2)

        if (ratio > 0) {
          this.trackEvent({
            action: 'bufferingRatio',
            label: ratio
          })
        }
      }
    }

    this.totalBufferingTime = 0
  }

  handleVideoFinish = () => {
    this._isPlayFinished = true
    this.clearVideoStatus()
  }

  clearVideoStatus = () => {
    this.trackWatchTimeAndBufferingRatio()

    this.setState({
      isPlaying: false,
      progress: 0
    })
  }

  handlePlaying = () => {
    if (this.bufferingStartTime > 0) {
      this.totalBufferingTime += new Date() - this.bufferingStartTime
      this.bufferingStartTime = 0
    }
  }

  componentDidMount () {
    this.viewTime = new Date()

    if (this.video.current && this.video.current.canPlayType && this.video.current.canPlayType('video/mp4; codecs="avc1"') === '') {
      this.setState({
        style: {
          display: 'none'
        }
      })
      this.props.onFailToLoad && this.props.onFailToLoad()
    }
  }



  render() {
    const isDesktop = this.props.$detector.isDesktop()
    return (
      <InView onChange={this.handleInViewChange} threshold={0.8} style={this.state.style}>
        <div
          onClick={this.handleClickVideo}
          className={cx(styles.wrapper, isDesktop && styles.inDesktop, this.props.className)}
        >
          <video
            src={this.props.src}
            poster={this.props.poster}
            controls={false}
            autoPlay={false}
            loop={false}
            preload='auto'
            ref={this.video}
            progress={this.state.progress}
            muted={this.state.muted}
            onTimeUpdate={this.handleTimeUpdate}
            onEnded={this.handleVideoFinish}
            playsInline={true}
            onError={this.handleError}
            onCanPlay={this.handleCanPlay}
            onWaiting={this.handleWaiting}
            onPlaying={this.handlePlaying}
            onLoadedMetadata={this.handleCanPlay}
          />
          {
            !this.state.isPlaying && <div className={styles.paused} />
          }
          <div className={cx(styles.voice, this.state.muted && styles.muted)} onClick={this.handleToggleMute}></div>
          <div className={styles.progress}>
            <NewProgressBar
              value={this.state.progress}
              outerBarClassName={styles.outerBar}
              innerBarClassName={styles.innerBar}
            />
          </div>
        </div>
      </InView>
    )
  }
}

export default withPlugin(VideoPlayer)
