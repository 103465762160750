import React, { useState, useContext, useEffect } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import FreeGiftHint from '../../../../components/Store/Coupon/FreeGiftHint'
import { withRegularCoupon } from '../../../../hooks/withRegularCouponEnabled'
import withCartAndPlugins from '../../../../hooks/withCartAndPlugins'
import WheelOverlayContainer from '../WheelOverlayContainer'
// import WebToAppOfferContainer from '../WebToAppOfferContainer'
import SupplementWheel from '../../../../components/Store/Coupon/SupplementWheel'
import WheelOverlayWinBackCard from '../../../../components/Store/Coupon/WheelOverlayWinBackCard'
import DrawContainer from '../DrawContainer'
import useFn from '../../../../hooks/useFn'
// import { isWebToAppDisabled } from '../../../../utils/Store/promotionUtils'

const WelcomeModalContainer = props => {
  const plugin = useContext(PluginContext)
  const { regularCouponMeta = {} } = props
  const { welcomeOffer, mobileWheelCoupons = [], finalOffer2 = {}, freeGift = {}, addedToCartCoupons = [], drawFlowOffer = [] } = regularCouponMeta

  const collectionCouponStorage = plugin.$storage.create('collection_coupons', { strategy: 'SESSION' })
  const beginSupplementStorage = plugin.$storage.create('begin_supplement_wheel', { strategy: 'SESSION' }) // 加车弹弹弹
  const _storage = plugin.$storage.create('_wl', { strategy: 'SESSION' }) // 大转盘是否交互
  const wheelInteractedStorage = plugin.$storage.create('wheel_interacted') // 抽奖flow根据这个开启
  const drawFlowTriedTimesStorage = plugin.$storage.create('draw_flow_tried_times')
  const drawPassedStorage = props.$storage.create('draw_passed_arr', { strategy: 'SESSION' })

  const [showWelcomeOffer, setWelcomeOfferShow] = useState(!_storage.getItem(false))

  const drawPassedResult = drawPassedStorage.getItem() || {}
  const [showDrawBuoy, setDrawBuoy] = useState(drawPassedResult.is && drawPassedResult.is.includes('DrawBuoy') && drawPassedResult.showFeature)

  const isDesktop = plugin.$detector.isDesktop()
  const renderElements = []

  const { locale } = plugin.$site.getSiteInfo()

  const hasWheelInteracted = !isDesktop && locale === 'en_US' && wheelInteractedStorage.getItem()

  // take 10%
  const handleWheelConfirm = () => {
    props.setMounted()
    _storage.setItem(true)
    wheelInteractedStorage.setItem(true)
    beginSupplementStorage.setItem(false)
    props.entryStorage.setItem(true)
    setWelcomeOfferShow(false)
  }

  const handleDrawClose = () => {
    props.entryStorage.setItem(true)
    props.setMounted()

    const triedTimes = drawFlowTriedTimesStorage.getItem(0)

    beginSupplementStorage.setItem(true)

    if (triedTimes === 0) { // 一个领品都没领，
      setDrawBuoy(true)
    } else if (triedTimes === 3) { // 都用完后下次session回到大转盘flow
      wheelInteractedStorage.setItem(false)
      drawFlowTriedTimesStorage.setItem(0)
      setDrawBuoy(false)
    } else {
      setDrawBuoy(true)
    }
  }

  if (!isDesktop && showDrawBuoy) {
    renderElements.push(
      <DrawContainer
        key="draw_buoy"
        setRuntimeData={props.setRuntimeData}
        runtimeData={props.runtimeData}
        drawFlowOffer={drawFlowOffer}
        onClose={handleDrawClose}
      />
    )
  }

  const processWheelCancelCallback = () => {
    props.setMounted()
    _storage.setItem(true)
    wheelInteractedStorage.setItem(true)
    beginSupplementStorage.setItem(true)
    props.entryStorage.setItem(true)

    const params = {
      type: 'wheel'
    }

    props.setRuntimeData('collection_coupons', params)
    collectionCouponStorage.setItem(params)
  }

  const directCloseWheel = useFn(() => {
    _storage.setItem(true)
    beginSupplementStorage.setItem(true)
    props.entryStorage.setItem(true)
  })


  // cancel 10%
  const handleWheelCancel = () => {

    setWelcomeOfferShow(false)

    if (!isDesktop) { // winback展示第一张大转盘券
      plugin.$confirm({
        welcomeOffer: mobileWheelCoupons[0],
        $i18n: props.$i18n,
        $track: props.$track
      }, WheelOverlayWinBackCard).then(yes => {
        if (yes) {
          setWelcomeOfferShow(true)
        } else {
          // give up
          processWheelCancelCallback()
        }
      })
    } else {
      processWheelCancelCallback()
    }
  }

  useEffect(() => {
    if (props.hideWheel) {
      directCloseWheel()
      setWelcomeOfferShow(false)
    }
  }, [props.hideWheel, directCloseWheel])



  if (!showWelcomeOffer && typeof props.footHintHeight === 'number') {// 大转盘已领，走free gift
    if (finalOffer2.hideFeature && freeGift.skuId) {

      if (!freeGift.hideFeature) {
        renderElements.push(
          <FreeGiftHint
            key="free_gift"
            freeGift={freeGift}
            footHintHeight={props.footHintHeight}
          />
        )
      }
    }
  } else if (showWelcomeOffer && !props.mounted) { // 展示大转盘
    if (!hasWheelInteracted) {
      if (welcomeOffer.hideFeature) {
        _storage.setItem(true)
        beginSupplementStorage.setItem(true)
        props.entryStorage.setItem(true)

        setWelcomeOfferShow(false)
        return
      }

      renderElements.push(
        <WheelOverlayContainer
          key="welcome1"
          show={showWelcomeOffer}
          onConfirm={handleWheelConfirm}
          onClose={handleWheelCancel}
          coupon={welcomeOffer}
          coupons={mobileWheelCoupons}
        />
      )
    } else {
      renderElements.push(
        <DrawContainer
          key="draw"
          setRuntimeData={props.setRuntimeData}
          runtimeData={props.runtimeData}
          drawFlowOffer={drawFlowOffer}
          onClose={handleDrawClose}
        />
      )
    }
  }

  // PC always on because of the buoy
  if (isDesktop && !hasWheelInteracted && !welcomeOffer.hideFeature) {
    renderElements.push(
      <WheelOverlayContainer
        key="welcome2"
        show={showWelcomeOffer}
        onConfirm={handleWheelConfirm}
        onClose={handleWheelCancel}
        coupon={welcomeOffer}
        isBuoy={true}
      />
    )
  }

  if (addedToCartCoupons.length) {
    renderElements.push(
      <SupplementWheel
        setRuntimeData={props.setRuntimeData}
        key="supplement_wheel"
        addedToCartCoupons={addedToCartCoupons}
      />
    )
  }

  return renderElements
}

export default withRegularCoupon(withCartAndPlugins(WelcomeModalContainer))