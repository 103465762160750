import React from 'react'

import withCartAndPlugins from '../../../hooks/withCartAndPlugins'

import {
  useShare,
  useNaviVisible,
  useHeaderProps,
} from './hooks/pageShellHooks'

import StoreHeader from '../Header/StoreHeader'
import StoreNavi from '../Navi/StoreNavi'
import FreeShippingBanner from '../../Widget/FreeShippingBanner/FreeShippingBanner'

import cx from '../../../utils/className'
import styles from './StorePageShell.module.css'

const StorePageShell = props => {
  const [naviVisible, toggleNavi, hideNavi] = useNaviVisible()
  const headerProps = useHeaderProps(props)
  const share = useShare(props)

  const {
    $router,
    children,
    $track,
    showShippingBar,
  } = props

  const handleShareClick = share
  const handleCartClick = () => $router.navigate({
    name: 'Cart'
  })
  const handleWishClick = () => $router.navigate({
    name: 'WishList'
  })
  const handleNaviClick = () => {
    $track.event('PageShell', 'click_navi')
    toggleNavi()
  }
  const handleNaviItemClick = hideNavi


  const handleContentClick =() => {
    if (naviVisible) {
      hideNavi(false)
    }
  }

  const handleSearch = value => {
    $router.navigate({
      name: 'Search',
      search: {
        q: value
      }
    })
    hideNavi()
  }

  const className = cx(styles.wrapper, {
    [styles.naviOpen]: naviVisible
  })

  const { hideHeader } = headerProps

  return (
    <section className={className}>
      <section className={styles.page}>
        {
          naviVisible &&
          <>
            <div className={styles.navigateCloseIcon} onClick={() => hideNavi()}>&#xe60e;</div>
            <div className={styles.navigationCover} onClick={() => hideNavi()} />
            <StoreNavi
              className={styles.navigation}
              onSearch={handleSearch}
              onNaviItemClick={handleNaviItemClick}
            ></StoreNavi>
          </>
        }


        <main className={styles.content} onClick={handleContentClick}>
          {
            !hideHeader && (
              <StoreHeader
                $router={$router}
                $track={$track}
                {...headerProps}
                onNaviClick={handleNaviClick}
                onCartClick={handleCartClick}
                onWishClick={handleWishClick}
                onShareClick={handleShareClick}
              ></StoreHeader>
            )
          }

          {
            showShippingBar && <FreeShippingBanner />
          }
          {children}
        </main>
      </section>
    </section>
  )
}

export default withCartAndPlugins(StorePageShell)
