import React, { PureComponent } from 'react'
import { rem as px2rem } from '../../../utils/remUtils'
import withCart from '../../../hooks/withCartAndPlugins'

import FreeGiftMobileHint from './FreeGiftMobileHint'
import FreeGiftPCHint from './FreeGiftPCHint'

class FreeGiftHint extends PureComponent {
  timer = null
  _storage = this.props.$storage.create('free_gift_take', { strategy: 'SESSION' })

  state = {
    loading: false,
    freeGiftTake: this._storage.getItem(false)
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({
        bottom: px2rem(this.props.footHintHeight)
      })
    }, 100)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  handleTake = ({
    skuId, triggerRef
  }) => {
    if (skuId) {
      return this.props.$addVariant({
        variant: { id: skuId },
        quantity: 1
      })
        .then(() => {
          this.props.$track.event({ category: 'Wheel', action: `take_free_gift`, nonInteraction: false })

          this.props.$store.startAtcParabola({
            startRef: triggerRef
          })

          this.setState({
            freeGiftTake: true
          })

          this._storage.setItem(true)

        })
        .catch(error => {
          this.setState({
            freeGiftTake: true
          })
          this._storage.setItem(true)
          this.props.$toastError(error.message)
        })
    }

    return Promise.resolve()
  }

  handleClose = () => {
    this.setState({
      freeGiftTake: true
    })
    this._storage.setItem(true)

    this.props.$track.event({ category: 'Wheel', action: `cancel_free_gift`, nonInteraction: false })
  }

  render() {
    const { productImage, skuId, link } = this.props.freeGift

    if (!skuId || this.state.freeGiftTake) {
      return null
    }

    const style = {}
    if (this.state.bottom) {
      style.bottom = this.state.bottom
    }

    const isDesktop = this.props.$detector.isDesktop()
    const RenderComponent = isDesktop ? FreeGiftPCHint : FreeGiftMobileHint

    return (
      <RenderComponent
        style={style}
        skuId={skuId}
        link={link}
        productImage={productImage}
        onTake={this.handleTake}
        onClose={this.handleClose}
      />
    )
  }
}

export default withCart(FreeGiftHint)