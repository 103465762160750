import React, { PureComponent } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import InView from '../InView/InView'

import cx from '../../../utils/className'
import styles from './FlexList.module.css'

class FlexList extends PureComponent {
  handleReachEnd = inView => {
    if (inView) {
      if (typeof this.props.onReachEnd === 'function') {
        this.props.onReachEnd()
      }
    }
  }

  /* --------------------------------------- */

  renderElements() {
    const { childIs, childItems, childOptions } = this.props

    const ChildComponent = childIs

    const isNeedTriggerReachEnd = i => i === childItems.length - 1

    if (childItems) {
      const elements = childItems.map((item, i) => {
        const element = (
          <ChildComponent
            key={i}
            index={i}
            {...item}
            {...childOptions}
          ></ChildComponent>
        )

        if (isNeedTriggerReachEnd(i)) {
          return (
            <>
              <InView onChange={this.handleReachEnd} triggerOnce={true} className={styles.blank} >
                <div />
              </InView>
              {element}
            </>
          )
        }

        return element
      })

      return elements
    } else {
      return []
    }
  }

  componentDidMount() {
    const noChild = !this.props.childItems || !this.props.childItems.length
    if (noChild && typeof this.props.onReachEnd === 'function') {
      this.props.onReachEnd()
    }
  }

  render() {
    const elements = this.renderElements()
    const isDesktop = this.props.$detector.isDesktop()
    const columns = this.props.columns || (isDesktop ? 4 : 2)
    const freeColumn = !this.props.columns

    return (
      <div className={cx(styles.list, freeColumn && styles.freeColumn, isDesktop && styles.isDesktop, this.props.className)} style={{ gridTemplateColumns: `repeat(${columns}, ${100 / columns}%)` }}>
        {
          elements.map((element, index) => (
            <div className={cx(styles.cardItem, this.props.columnClassName)} key={index}>{element}</div>
          ))
        }
      </div>
    )
  }
}

export default withPlugin(FlexList)
