import React, { PureComponent, createRef } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Slider from '../../common/Slider/Slider'
import SingleColumn from '../../common/List/SingleColumn'

// import Image from '../../common/Image/Image'
import ProductImage from '../../common/ProductImage/ProductImage'
import VideoPlayer from '../../common/Video/VideoPlayer'
import { rem as px2rem } from '../../../utils/remUtils'

import styles from './ProductPhotoSwipe.module.css'
import cx from '../../../utils/className'

const ImageThumbnail = props => {
  const { itemIndex, currentIndex } = props

  return (
    <div onMouseOver={() => props.onMouseOver(itemIndex)}>
      <ProductImage
        objectFit="cover"
        {...props}
        tiny
        className={cx(styles.thumbnail, itemIndex === currentIndex && styles.highlighted)}
      />
    </div>
  )
}

class ProductPhotoSwipe extends PureComponent {

  state = {
    curIndex: null,
    showSwipeButton: false,
    sliderSpeed: 400,
    items: null
  }

  sliderEl = createRef()

  componentDidUpdate(prevProps) {
    if (prevProps.curImageIndex !== this.props.curImageIndex) {
      this.sliderEl.current.slickGoTo(this.props.curImageIndex)
    }
  }

  swipeChangeHandler = index => {
    this.setState({
      curIndex: index
    })
  }

  handleLeftSwipe = () => {
    this.setState({
      sliderSpeed: 400
    })
    this.sliderEl.current.slickPrev()
  }

  handleRightSwipe = e => {
    this.setState({
      sliderSpeed: 400
    })
    e.stopPropagation()
    this.sliderEl.current.slickNext()
  }

  handleHoverThumbnail = itemIndex => {
    this.setState({
      curIndex: itemIndex,
      sliderSpeed: 0
    })

    this.sliderEl.current.slickGoTo(itemIndex)
  }

  handleHoverSlider = () => {
    this.setState({
      showSwipeButton: true
    })
  }

  handleMouseOutSlider = () => {
    this.setState({
      showSwipeButton: false
    })
  }

  renderPageNum = length => {
    if (typeof this.state.curIndex === 'number') {
      return `${this.state.curIndex + 1}/${length}`
    }

    return `${this.props.curImageIndex + 1}/${length}`
  }


  handleVideoFailToLoad = () => {
    // hide video
    const items = this.props.items.filter(item => item.type !== 'VIDEO')

    this.setState({
      items
    })
  }

  render() {
    const style = this.props.height ? { height: px2rem(this.props.height) } : null
    const items = this.state.items || this.props.items
    const title = this.props.product && this.props.product.title

    const images = items.map((item, i) => {
      const imgTitle = item.title || (title && `${title} gallery ${i + 1}`) || `Gallery - ${i + 1}`
      return (
        <div key={i} className={styles.photoSwipe}>
          {
            item.type === 'VIDEO'
            ?
            <VideoPlayer src={item.videoUrl} poster={item.src} className={cx(styles.photoWrapper, styles.videoWrapper, this.props.flexible && styles.flexible)} style={style} onFailToLoad={this.handleVideoFailToLoad}/>
            :
            (
              i === 0
              ?
              <div className={styles.firstImgWrapper}>
                <ProductImage objectFit="cover" {...item} className={cx(styles.photoWrapper, this.props.flexible && styles.flexible)} style={style} title={imgTitle} middle></ProductImage>
              </div>
              :
              <ProductImage objectFit="cover" {...item} className={cx(styles.photoWrapper, this.props.flexible && styles.flexible)} style={style} title={imgTitle} middle lazy></ProductImage>
            )
          }
        </div>
      )
    })

    if (images.length === 0) {
      return null
    }

    const isDesktop = this.props.$detector.isDesktop()
    const sliderSettings = {
      variableWidth: true,
      speed: 170
    }


    return (
      <div className={cx(styles.wrapper, isDesktop && styles.inDesktop, this.props.minSliderWrapper && styles.smallSize, this.props.swiperSize)} style={style}>
        {
          isDesktop ?
          <div className={cx(styles.desktopWrapper, this.props.flexible && styles.flexible)}>
            <div className={cx(styles.thumbnails, styles.leftSide)}>
              <SingleColumn
                childIs={ImageThumbnail}
                childItems={items}
                childOptions={{
                  onMouseOver: this.handleHoverThumbnail,
                  currentIndex: this.state.curIndex
                }}
                onReachEnd={() => null}
              ></SingleColumn>
            </div>
            <div className={cx(styles.sliderWrapper, isDesktop && styles.inDesktop, styles.rightSide)} onScroll={e => e.stopPropagation()} onMouseEnter={this.handleHoverSlider} onMouseLeave={this.handleMouseOutSlider}>
              {

                this.state.showSwipeButton && <>
                  {
                    isDesktop ?
                    <>
                      <div className={cx(styles.pcSwiperButton, styles.pcLeftButton)} onClick={this.handleLeftSwipe} >
                        <div className={styles.pcLeftButtonArrow} />
                      </div>
                      <div className={cx(styles.pcSwiperButton, styles.pcRightButton)} onClick={this.handleRightSwipe} >
                        <div className={styles.pcRightButtonArrow} />
                      </div>
                    </>
                    :
                    <>
                      <div className={styles.leftButton} onClick={this.handleLeftSwipe} />
                      <div className={styles.rightButton} onClick={this.handleRightSwipe} />
                    </>
                  }
                </>
              }
              <Slider afterChange={this.swipeChangeHandler} ref={this.sliderEl} infinite={true} speed={this.state.sliderSpeed}>
                {images}
              </Slider>
            </div>
          </div>
          :
          <>
            <Slider initialSlide={this.props.curImageIndex} afterChange={this.swipeChangeHandler} ref={this.sliderEl} infinite={true} {...sliderSettings}>
              {images}
            </Slider>
            <div className={styles.pagination}>{this.renderPageNum(images.length)}</div>
          </>
        }
      </div>
    )
  }
}

export default withPlugin(ProductPhotoSwipe)
