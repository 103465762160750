export const ORDER_TYPE = {
  DRAFT: 'draft',
  ORDER: 'order',
  GROUP_BUY: 'groupBuy',
  LUCKY_BAG: 'luckyBag',
  ANONYMOUS: 'anonymous',
  COIN: 'coin'
}

export const PAYMENT_METHOD_TYPE = {
  BRAINTREE_CARD: 'braintree_card',
  BRAINTREE_PAYPAL: 'braintree_paypal',
  BRAINTREE_PAYPAL_PAYLATER: 'braintree_paypal_paylater',
  BRAINTREE_GOOGLEPAY: 'braintree_googlepay',
  BRAINTREE_APPLEPAY: 'braintree_applepay',
  STRIPE_CARD: 'stripe_card',
  STRIPE_AFTERPAY: 'stripe_afterpay',
  KLARNA: 'klarna',
  STRIPE_KLARNA: 'stripe_klarna'
}
export const DEFAULT_PAYMENT_METHODS = {
  OTHERS: [
    {
      id: PAYMENT_METHOD_TYPE.STRIPE_CARD,
      mainPayMethod: 'stripe',
      subPayMethod: 'card',
      subPayMethods: ['card'],
      active: true
    },
    {
      id: PAYMENT_METHOD_TYPE.BRAINTREE_PAYPAL,
      mainPayMethod: 'braintree',
      subPayMethod: 'paypal',
      subPayMethods: ['paypal'],
      active: true
    },
    {
      id: PAYMENT_METHOD_TYPE.KLARNA,
      mainPayMethod: 'klarna',
      subPayMethod: 'klarna',
      subPayMethods: ['klarna'],
      active: true
    },
  ],
  US: [
    {
      id: PAYMENT_METHOD_TYPE.BRAINTREE_CARD,
      mainPayMethod: 'braintree',
      subPayMethod: 'card',
      subPayMethods: ['card'],
      active: true
    },
    {
      id: PAYMENT_METHOD_TYPE.BRAINTREE_PAYPAL,
      mainPayMethod: 'braintree',
      subPayMethod: 'paypal',
      subPayMethods: ['paypal'],
      active: true
    },
    {
      id: PAYMENT_METHOD_TYPE.KLARNA,
      mainPayMethod: 'klarna',
      subPayMethod: 'klarna',
      subPayMethods: ['klarna'],
      active: true
    }
  ]
}


export const PAYMENT_METHOD = {
  BRAINTREE: {
    id: 'braintree',
    subPayments: {
      CARD: {
        id: PAYMENT_METHOD_TYPE.BRAINTREE_CARD, // help to distinguish card payment among braintree/checkout/stripe...
        name: 'card'
      },
      PAYPAL: {
        id: PAYMENT_METHOD_TYPE.BRAINTREE_PAYPAL,
        name: 'paypal'
      },
      GOOGLEPAY: {
        id: PAYMENT_METHOD_TYPE.BRAINTREE_GOOGLEPAY,
        name: 'googlepay'
      },
      APPLEPAY: {
        id: PAYMENT_METHOD_TYPE.BRAINTREE_APPLEPAY,
        name: 'applepay'
      }
    }
  },
  STRIPE: {
    id: 'stripe',
    subPayments: {
      CARD: {
        id: PAYMENT_METHOD_TYPE.STRIPE_CARD,
        name: 'card'
      },
    }
  },
  KLARNA: {
    id: PAYMENT_METHOD_TYPE.KLARNA
  }
}

export const BRAINTREE_INVALID_CARD_FIELDS = [
  {
    key: 'number',
    i18nKey: 'core.checkout.validations.invalidCardNumber'
  },
  {
    key: 'cardholderName',
    i18nKey: 'core.checkout.validations.invalidCardHolderName'
  },
  {
    key: 'expirationDate',
    i18nKey: 'core.checkout.validations.invalidExpirationDate'
  },
  {
    key: 'cvv',
    i18nKey: 'core.checkout.validations.invalidCardCVV'
  }
]


export const BILLING_ADDRESS_METHOD = {
  SAME_AS_SHIPPING: 'same_as_shipping',
  USE_DIFFERENT: 'use_different'
}

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const getTrackCouponName = (coupon) => {
  const couponId = coupon && coupon.id

  if (couponId && coupon.meta) {
    const couponMeta = coupon.meta
    const couponDescriptor = couponMeta.discount && couponMeta.discount.code
      ? couponMeta.discount.code
      : couponMeta.title

    return `${couponId} (${couponDescriptor})`
  } else if (couponId) {
    return couponId
  } else {
    return 'N/A'
  }
}

export const getDefaultAddress = (userAddresses) => {
  let defaultAddress = userAddresses.filter(item => item.hasDefault)[0]

  // 若数据库中addressList里没有默认地址 那么容错一下
  if (!defaultAddress && userAddresses.length > 0) {
    defaultAddress = userAddresses[0]
  }
  return defaultAddress
}

export const transferAPP2PayPalShippingAddress = (selectedAddress = {}) => {
  return {
    name:  {
      full_name: `${selectedAddress.firstName} ${selectedAddress.lastName}`,
    },
    shipping_address: {
      line1: selectedAddress.addressLine1,
      line2: selectedAddress.addressLine2,
      city: selectedAddress.cityName,
      country_code: selectedAddress.countryCode,
      postal_code: selectedAddress.zipCode,
      state: selectedAddress.stateName,
      phone: selectedAddress.telNumber
    }
  }
}

export const transferAPP2BraintreePayPalShippingAddress = (selectedAddress = {}) => {
  return {
    recipientName: `${selectedAddress.firstName} ${selectedAddress.lastName}`,
    line1: selectedAddress.addressLine1,
    line2: selectedAddress.addressLine2,
    city: selectedAddress.cityName,
    countryCode: selectedAddress.countryCode,
    postalCode: selectedAddress.zipCode,
    state: selectedAddress.stateName,
    phone: selectedAddress.telNumber
  }
}

export const transferBraintreePayPal2APPShippingAddress = props => {
  const { email, firstName, lastName, shippingAddress = {}} = props

  return {
    lastName: lastName,
    firstName: firstName,
    countryCode: shippingAddress.countryCode,
    countryName: shippingAddress.countryCode,
    stateCode: shippingAddress.state || shippingAddress.countryCode,
    stateName: shippingAddress.state || shippingAddress.countryCode,
    cityName: shippingAddress.city,
    addressLine1: shippingAddress.line1,
    addressLine2: shippingAddress.line2,
    zipCode: shippingAddress.postalCode,
    email: email
  }
}

export const transferBraintreeGooglePay2APPShippingAddress = props => {
  const { email, shippingAddress = {}} = props

  return {
    lastName: shippingAddress.name,
    firstName: '',
    countryCode: shippingAddress.countryCode,
    countryName: shippingAddress.countryCode,
    stateCode: shippingAddress.administrativeArea || shippingAddress.countryCode,
    stateName: shippingAddress.administrativeArea || shippingAddress.countryCode,
    cityName: shippingAddress.locality,
    addressLine1: shippingAddress.address1,
    addressLine2: shippingAddress.address3 ? `${shippingAddress.address2} ${shippingAddress.address3}`.trim() : shippingAddress.address2,
    zipCode: shippingAddress.postalCode,
    email
  }
}

export const transferBraintreeApplePay2APPShippingAddress = props => {
  const { shippingContact } = props

  return {
    lastName: shippingContact.familyName,
    firstName: shippingContact.givenName,
    countryCode: shippingContact.countryCode,
    countryName: shippingContact.country,
    stateCode: shippingContact.administrativeArea || shippingContact.countryCode,
    stateName: shippingContact.administrativeArea || shippingContact.countryCode,
    cityName: shippingContact.locality,
    addressLine1: shippingContact.addressLines[0],
    addressLine2: shippingContact.addressLines[1] || '',
    zipCode: shippingContact.postalCode,
    telNumber: shippingContact.phoneNumber,
    email: shippingContact.emailAddress
  }
}

export const transferPayPal2APPShippingAddress = props => {
  const { payer, purchase_units } = props

  const { shipping = {}} = purchase_units[0]

  const { address, name } = shipping

  if (!address) {
    return null
  }

  const names = (name.full_name || '').split(' ')

  return {
    lastName: names[0],
    firstName: names[1],
    countryCode: address.country_code,
    countryName: address.country_code,
    stateCode: address.admin_area_1 || address.admin_area_2,
    stateName: address.admin_area_1 || address.admin_area_2,
    cityName:address.admin_area_2,
    addressLine1: address.address_line_1,
    addressLine2: address.address_line_2,
    zipCode: address.postal_code,
    telNumber: payer.phone && payer.phone.phone_number && payer.phone.phone_number.national_number,
    email: payer.email_address
  }
}

export const transferAPP2StripeAfterpayKlarnaShippingAddress = shippingAddress => {
  return {
    name: `${shippingAddress.firstName} ${shippingAddress.lastName}`,
    address: {
      line1: shippingAddress.addressLine1,
      line2: shippingAddress.addressLine2,
      city: shippingAddress.cityName,
      state: shippingAddress.stateName,
      country: shippingAddress.countryCode,
      postal_code: shippingAddress.zipCode
    },
    phone: shippingAddress.telNumber
  }
}

export const getRewardCoinsString = (obtainCoins, obtainCoinsInfo) => {
  if ((!obtainCoins || obtainCoins <= 0) && (!obtainCoinsInfo || obtainCoinsInfo.willObtainCoins <= 0)) {
    return ''
  }

  // be compatible with legacy data structure: only has params -- obtainCoins
  if (!obtainCoinsInfo) {
    return parseInt(obtainCoins, 10).toLocaleString()
  } else {
    const { willObtainCoins, baseObtainCoins, obtainCoinsMultiple } = obtainCoinsInfo
    const willObtainCoinsString = parseInt(willObtainCoins, 10).toLocaleString()
    const baseObtainCoinsString = parseInt(baseObtainCoins, 10).toLocaleString()

    return obtainCoinsMultiple > 1
    ? `${baseObtainCoinsString} x ${obtainCoinsMultiple} = ${willObtainCoinsString}`
    : willObtainCoinsString
  }
}

export const COUNTRY_MOBILE_PREFIX = {
  US: '+1',
  JP: '+81',
  GB: '+44',
  CA: '+1',
  MX: '+52',
  PR: '+1787',
  ES: '+34',
  BR: '+55',
  FR: '+33',
  DE: '+49',
  SE: '+46'
}

export const formateTelNumber = ({ countryCode = 'US', telNumber }) => {
  return telNumber && `${COUNTRY_MOBILE_PREFIX[countryCode]}${telNumber}`
}
