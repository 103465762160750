import queryString from 'query-string'
import ClientOnlyPlugin from '@flamingo_tech/funkgo/src/base/ClientOnlyPlugin'
import CollectClient from './TrackerPlugin/CollectClient'
import createListener from '@flamingo_tech/funkgo/src/utils/createListener'

import { install as installGa } from '@flamingo_tech/funkgo/src/sdk/gaSdk'
import { install as installGtag } from '@flamingo_tech/funkgo/src/sdk/gtagSdk'
import { install as installGTM } from '@flamingo_tech/funkgo/src/sdk/gtmSdk'
import { install as installPixel } from '@flamingo_tech/funkgo/src/sdk/pixelSdk'
import { install as installTiktok } from '@flamingo_tech/funkgo/src/sdk/tiktokSdk'
import { install as installBing } from '@flamingo_tech/funkgo/src/sdk/bingSdk'
import { install as installCmp } from '@flamingo_tech/funkgo/src/sdk/cmpSdk'

import { getCookieItem } from '../utils/cookieUtils'
import { COUNTRY_MOBILE_PREFIX } from '../utils/Checkout/checkoutUtils'

import {
  parseEventPayload,
  parseEventOptions,
  getPixelBySourceLocation
} from './TrackerPlugin/trackerUtils'

/* ---------------------------------- */

const GA_EC_ACTION = {
  VIEW_PRODUCT: 'Viewed Product',
  ADD_PRODUCT: 'Added Product',
  INITIATE_CHECKOUT: 'Checkout',
  PURCHASE: 'Purchase',
}

const EC_CATEGORY = 'Ecommerce'

const DEFAULT_CURRENCY = 'USD'
const DEFAULT_ORIGIN = 'https://flamingo.shop'
const DEFAULT_COUNTRY_CODE = 'US'

/* ---------------------------------- */

export default class TrackerPlugin extends ClientOnlyPlugin {
  displayName = '$Tracker'

  start() {
    this.currentPage = undefined
    this.initListener()
    this.initSiteData()
    this.initCollect()

    this.initGa()
    this.initGtag()
    this.initPixel()
    this.initTiktok()
    this.initBing()
    this.initCmp()
  }

  /* ---------------------------------- */

  initListener = () => {
    this.listener = createListener()
  }

  subscribeTrack = (fn, options) => {
    this.listener.subscribe(fn, options)
  }

  unsubscribeTrack = (fn, options) => {
    this.listener.unsubscribe(fn, options)
  }

  /* ---------------------------------- */

  /* ---------------------------------- */
  canInstallScript() {
    const $detector = this.pluginHub.getDetector()
    return !$detector.isServer() && process.env.NODE_ENV !== 'test'
  }

  isApp() {
    const $detector = this.pluginHub.getDetector()
    return $detector.isApp()
  }

  // for some track which for ad, e.g. fb pixel, tiktok
  shouldTrackForAd() {
    return this.canInstallScript() && process.env.NODE_ENV === 'production'
  }

  /* ---------------------------------- */
  initSiteData() {
    const $site = this.pluginHub.getSite()

    const siteInfo = $site.getSiteInfo({
      pluginHub: this.pluginHub
    }) || {}

    this.currencyCode = siteInfo.currency || DEFAULT_CURRENCY
    this.countryCode = siteInfo.countryCode || DEFAULT_COUNTRY_CODE
    this.websiteOrigin = siteInfo.origin || DEFAULT_ORIGIN
  }

  /* ---------------------------------- */
  canInstallGa() {
    return this.canInstallScript() && this.options.ga
  }

  initGa() {
    if (this.canInstallGa()) {
      installGa()

      this.ga('create', this.options.ga, 'auto', {
        allowLinker: true,
      })

      this.ga('require', 'ec')
      this.ga('require', 'displayfeatures')
      this.ga('require', 'linker')
      this.ga('linker:autoLink', ['flamingo.shop'])
      this.ga('set', 'currencyCode', this.currencyCode)


      const $detector = this.pluginHub.getDetector()

      // same as name at package.json
      this.ga('set', 'appName', $detector.getClientName())

      // if in app, version format is `${appVersion}:${version at package.json}`
      // otherwise is version at package.json
      this.ga('set', 'appVersion', $detector.getClientVersion())


      if (!$detector.isWebStore()) {
        this.ga('set', 'dimension1', 'FlamingoApp')
      }

      // do not track page view here
      // once every page loaded, it will call $tracker.pv() automatically
      // so it can cover first page loaded, and SPA pagination
    }
  }

  hasGa() {
    return this.canInstallGa() && Boolean(window.ga)
  }

  ga(event, ...args) {
    if (this.hasGa()) {
      window.ga(event, ...args)
    }
  }

  gaEvent(payload) {
    this.ga('send', 'event', {
      eventCategory: payload.category,
      eventAction: payload.action,
      eventLabel: payload.label,
      eventValue: payload.value,
      nonInteraction: payload.nonInteraction,
    })
  }
  /* ---------------------------------- */
  canInstallGtag() {
    return this.shouldTrackForAd() && this.options.googleAds
  }

  gtag() {
    if (window.dataLayer) {
      window.dataLayer.push(arguments)
    }
  }

  initGtag() {
    if (this.canInstallGtag()) {
      installGtag({ clientId: this.options.googleAds })

      window.dataLayer = window.dataLayer || []
      this.gtag('js', new Date())

      this.gtag('config', this.options.googleAds, { 'allow_enhanced_conversions': true })
    }
  }
  /* ---------------------------------- */
  gtagSendConversion(conversionId, payload = {}) {
    if (this.canInstallGtag() && Boolean(window.dataLayer)) {
      this.gtag('event', 'conversion', {
        'send_to': conversionId,
        ...payload
      })
    }
  }
  /* ---------------------------------- */

  initGTM() {
    if (this.shouldTrackForAd() && this.options.gtm) {
      installGTM(this.options.gtm)
    }
  }

  /* ---------------------------------- */
  canInstallPixel() {
    return this.shouldTrackForAd() && this.options.pixels && !this.isApp()
  }

  initPixel() {
    if (this.canInstallPixel()) {
      installPixel()

      let pixel

      const pixelWithSourceLocation = getPixelBySourceLocation(this.pluginHub.getStorage())

      if (pixelWithSourceLocation) {
        pixel = pixelWithSourceLocation
      } else {
        const $site = this.pluginHub.getSite()

        const { id = '', countryCode = '' } = $site.getSiteInfo({
          pluginHub: this.pluginHub
        })

        const sitePixel = this.options.pixels[id.toLowerCase()] || this.options.pixels.flamingo

        pixel = sitePixel[countryCode.toLowerCase()] || sitePixel.default
      }

      this.fbq('init', pixel)
      this.fbq('track', 'PageView')

      // track page view for first page loaded
      // for SPA pagination, fb pixel will auto track it
    }
  }

  hasPixel() {
    return this.canInstallPixel() && Boolean(window.fbq)
  }

  fbq = (event, ...args) => {
    if (this.hasPixel()) {
      window.fbq(event, ...args)
    }
  }
  /* ---------------------------------- */

  canInstallTiktok() {
    return this.shouldTrackForAd() && !this.isApp()
  }

  initTiktok() {
    if (this.canInstallTiktok()) {
      installTiktok({ clientId: this.options.tiktok })
    }
  }

  tiktokTrack(event, payload) {
    if (this.canInstallTiktok() && window.ttq && window.ttq.track && typeof (window.ttq.track) === 'function') {
      const $user = this.pluginHub.getPlugin('user')
      $user.getUserPhoneNumber()
        .then(telNumber => {
          const email = $user.getUserEmail()
          const params = {}

          if (email) {
            params.email = email
          }

          if (telNumber) {
            params.phone_number = telNumber
          }

          if (Object.keys(params).length !== 0) {
            window.ttq.identify(params)
          }

          window.ttq.track(event, payload)
        })
    }
  }

  /* ---------------------------------- */

  canInstallSnapchat() {
    return this.shouldTrackForAd() && this.options.snapchat && false
  }

  snapchatTrack(action, options) {
    if (this.canInstallSnapchat()) {
      if (options) {
        window.snaptr && window.snaptr('track', action, options)
      } else {
        window.snaptr && window.snaptr('track', action)
      }
    }
  }

  /* ---------------------------------- */
  initBing() {
    if (this.canInstallScript()) {
      installBing()
    }
  }

  hasBing() {
    return this.canInstallScript() && Boolean(window.uetq) && !this.isApp()
  }


  initCmp() {
    if (this.canInstallScript() && this.canInstallCmp()) {
      installCmp()
    }
  }

  canInstallCmp() {
    const $site = this.pluginHub.getSite()
    const $detector = this.pluginHub.getDetector()

    const { countryCode = 'US' } = $site.getSiteInfo({
      pluginHub: this.pluginHub
    }) || {}

    return (!$detector || !$detector.isApp()) && ['GB', 'DE', 'FR'].indexOf(countryCode) > -1
  }

  getBingProductItem(productVariant) {
    let item = {
      id: productVariant.sku,
      price:  productVariant.price,
      quantity: productVariant.quantity,
    }
    return item
  }

  bingTrackViewedItem(productVariant) {
    if (this.hasBing()) {
      const item = this.getBingProductItem({ ...productVariant, quantity: 1 })
      window.uetq.push('event', 'Product Page View', {
         items: [ item ],
          ecomm_pagetype: 'product',
          ecomm_prodid: [ item.id ],
      })
    }
  }

  bingTrackAddToCart(productVariant) {
    if (this.hasBing()) {
      const addToCartItem = this.getBingProductItem({ ...productVariant, quantity: 1 })
      const page = this.getPage()
      let pageType = 'other'
      if (page.indexOf('/store/product') > -1) {
        pageType = 'product'
      } else if (page.indexOf('/store/collection') > -1) {
        pageType = 'category'
      } else if (page.indexOf('/store/cart') > -1) {
        pageType = 'cart'
      } else if (page.indexOf('/store/search') > -1) {
        pageType = 'searchresults'
      } else if (page === '/store' || page === '/store/home') {
        pageType = 'home'
      }
      window.uetq.push('event', 'Add To Cart', {
        ecomm_pagetype: pageType,
        ecomm_prodid: [ addToCartItem.id ],
        ecomm_totalvalue: productVariant.price,
        revenue_value: productVariant.price,
        currency: productVariant.currencyCode,
        items: [ addToCartItem ]
      })
    }
  }

  bingTrackPurchase(order) {
    if (this.hasBing()) {
      const items = order.lineItems.map(lineItem => this.getBingProductItem({ ...lineItem.productVariant, quantity: lineItem.quantity }))
      window.uetq.push('event', 'Purchase', {
        transaction_id: order.id,
        ecomm_prodid: items.map(p => p.id),
        ecomm_pagetype: 'purchase',
        ecomm_totalvalue: order.totalPrice,
        revenue_value: order.totalPrice,
        event_value: order.totalPrice,
        currency: order.currencyCode,
        items: items,
      })
    }
  }

  bingTrackInitiateCheckout(order) {
    if (this.hasBing()) {
      const items = order.lineItems.map(lineItem => this.getBingProductItem({ ...lineItem.productVariant, quantity: lineItem.quantity }))
      window.uetq.push('event', 'Initiate Checkout', {
        revenue_value: order.totalPrice,
        items: items,
        currency: order.currencyCode,
      })
    }
  }

  /* ---------------------------------- */
  isBotTraffic() {
    const $detector = this.pluginHub.getDetector()

    return $detector && $detector.isBot()
  }

  canInstallCollect() {
    return this.canInstallScript() && process.env.NODE_ENV === 'production' && window.location.origin !== 'https://test.flamingo.shop'
  }

  initCollect() {
    if (this.canInstallCollect()) {
      this.$collectClient = new CollectClient({
        pluginHub: this.pluginHub
      })
    }
  }

  hasCollect() {
    return this.canInstallCollect() && Boolean(this.$collectClient)
  }

  // track to Flamingo self-own tracking system
  collectTrack(payload) {
    const payloadWithPage = {
      ...payload,
      page: this.getPage() || 'N/A',
      prevPage: this.getPrevPage(),
      reportTime: new Date().getTime()
    }

    if (this.hasCollect()) {
      this.$collectClient.push(payloadWithPage)
    } else {
      console.log(payloadWithPage)
    }
    this.listener.notify(payloadWithPage)
  }

  /* ---------------------------------- */

  getPageName(location) {
    const $detector = this.pluginHub.getDetector()
    const query = queryString.parse(location.search)
    let pageName = location.pathname
    const pageSearch = []

    // trim end "/"
    if (pageName.length > 1 && pageName.charAt(pageName.length - 1) === '/') {
      pageName = pageName.substr(0, pageName.length - 1)
    }

    Object.keys(query).sort().forEach(key => {
      if (key.indexOf('utm') === -1
        && key.indexOf('fbclid') === -1
        && key.indexOf('mcp_token') === -1
        && key.charAt(0) !== '_'
      ) {
        const value = query[key]
        pageSearch.push(value ? `${key}=${query[key]}` : key)
      }
    })

    if ($detector.isApp()) {
      pageSearch.push('FlamingoApp=1')
    } else if ($detector.isPwa()) {
      pageSearch.push('FlamingoApp=pwa')
    }

    if (pageSearch.length) {
      return `${pageName}?${pageSearch.join('&')}`
    }

    return pageName
  }

  setPage = (location = window.location) => {
    const pageName = this.getPageName(location)
    this.currentPage = pageName
    this.ga('set', 'page', pageName)
    this.ga('set', 'title', pageName)
  }

  getPage = () => this.currentPage

  getPrevPage = (history = window.history) => {
     const { state } = history.state || {}

     if (state && state.from) {
      const pageName = this.getPageName(state.from)

      return pageName
     } else {
      return 'N/A'
     }
  }

  /* comment events */
  /* ---------------------------------- */

  pv = (location = window.location) => {
    if (this.isBotTraffic()) {
      return
    }

    this.setPage(location)
    this.ga('send', 'pageview')
    const pageName = this.getPageName(location)
    this.trackGa4EcAction('page_view', {
      page_title: pageName,
      page_path: location.pathname,
      page_location: window.location.href,
    })
    // pixel do not need to track pv

    this.collectTrack({
      category: 'PageView',
      action: this.getPage()
    })

    // for other trackers like fb pixel, tiktok, it will track pagination automatically
  }

  event = (...args) => {
    if (this.isBotTraffic()) {
      return
    }

    const eventPayload = parseEventPayload(...args)
    const eventOptions = parseEventOptions(...args)

    this.gaEvent(eventPayload)

    const $detector = this.pluginHub.getDetector()
    this.gtag('event', eventPayload.action, {
      send_to: this.options.ga4,
      event_category: eventPayload.category,
      event_label: eventPayload.label,
      event_value: eventPayload.value,
      currency_code: this.currencyCode,
      app_name: $detector.getClientName(),
      app_version: $detector.getClientVersion(),
      non_interaction: eventPayload.nonInteraction,
    })

    if (!eventOptions || eventOptions.collectDisabled !== true) {
      this.collectTrack(eventPayload)
    }
  }

  /* error events */
  /* ---------------------------------- */

  error({
    isFatal = false,
    category = '',
    error,
    label = '',
    options
  }) {
    let errCategory
    let errMsg
    let errLabel
    let hasStack = false

    errCategory = ['ERROR', category, isFatal ? 'FATAL' : '']
      .filter(Boolean)
      .join('_')

    if (error && error.message) {
      errMsg = error.message
      if (label) {
        errLabel = label
      } else if (error.stack) {
        errLabel = error.stack
        hasStack = true
      } else {
        errLabel = 'no_stack'
      }
    } else {
      if (typeof error === 'string') {
        errMsg = error
      } else if (typeof error === 'object') {
        errMsg = JSON.stringify(error)
      } else {
        errMsg = String(error)
      }
      errLabel = label
    }

    if (!(errCategory === 'ERROR_UNCATCHED_ERROR' && errMsg === '[N/A] Script error.:(0-0)' && errLabel === 'no_stack')) {
      // we use ga to track error currently, so map error prop to ga prop for data view
      this.event({
        category: errCategory,
        action: errMsg,
        label: errLabel,
        nonInteraction: true,
        options
      })
    }


    if (process.env.NODE_ENV === 'development') {
      if (console && console.error) {
        if (hasStack) {
          console.error(`[${errCategory}] ${errMsg}`)
        } else {
          console.error(`[${errCategory}] ${errMsg} - (${errLabel})`)
        }
      }
    }
  }

  /* ---------------------------------- */

  flush = () => {
    if (this.canInstallCollect()) {
      return this.$collectClient.flush()
    } else {
      return Promise.resolve()
    }
  }

  /* ec tracking */
  /* ---------------------------------- */
  checkProductArgs(productVariant, product) {
    if (!productVariant) {
      throw new Error(`[TrackingPlugin] must provide productVariant as first option for ec tracking`)
    }

    if (!product) {
      throw new Error(`[TrackingPlugin] must provide product as second option for ec tracking`)
    }
  }

  getTrackProductName = (productVariant, product) => {
    return `${product.title} - ${productVariant.title}`
  }

  /* ------------------------------------------ */

  makeFbTrackContent(productVariant, product) {
    return {
      content_type: 'product',
      content_ids: [productVariant.id],
      value: productVariant.price,
      content_name: product.title,
      currency: this.currencyCode,
      content_category: product.category
    }
  }

  makeCollectTrackContentProduct(product) {
    return {
      id: product.id,
      handle: product.handle,
      category: product.category,
      vendor: product.vendor,
    }
  }

  makeCollectTrackContentLineItem(productVariant, product, quantity = 1) {
    return {
      ...this.makeCollectTrackContentProduct(product),
      variantId: productVariant.id,
      variantSku: productVariant.sku,
      price: productVariant.price,
      quantity,
      currencyCode: this.currencyCode,
      title: this.getTrackProductName(productVariant, product),
      variantTitle: productVariant.title
    }
  }

  makeCollectTrackContentOrder(order, couponName) {
    return {
      transactionId: order.id,
      revenue: order.totalPrice,
      couponName,
      freight: order.totalShipping,
      lineItems: order.lineItems.map(({ productVariant, product, quantity }) => (
        this.makeCollectTrackContentLineItem(productVariant, product, quantity)
      )),
      currencyCode: this.currencyCode,
    }
  }


  /* ------------------------------------------ */
  trackGaEcAction(actionName, ...args) {
    this.ga('ec:setAction', actionName, ...args)
  }

  trackGa4EcAction(actionName, parameters = {}) {
    const $detector = this.pluginHub.getDetector()
    this.gtag('event', actionName, {
      send_to: this.options.ga4,
      currency_code: this.currencyCode,
      app_name: $detector.getClientName(),
      app_version: $detector.getClientVersion(),
      ...parameters,
    })
  }

  trackGAWithProduct(productVariant, product, quantity = 1) {
    this.ga('ec:addProduct', {
      id: productVariant.sku,
      name: this.getTrackProductName(productVariant, product),
      category: product.category,
      brand: product.vendor,
      price: productVariant.price,
      quantity,
      variant: productVariant.title
    })
  }

  getTrackGA4ProductItem(product, productVariant) {
    let item = {
      item_id: productVariant.sku,
      item_name: this.getTrackProductName(productVariant, product),
      currency: productVariant.currencyCode,
      item_brand: product.vendor,
      item_category: product.category,
      item_variant: productVariant.title,
      price:  productVariant.price,
      quantity: productVariant.quantity,
    }
    return item
  }

  trackGA4ViewProduct(productVariant, product) {
    const viewItem = this.getTrackGA4ProductItem(product, { ...productVariant, quantity: 1 })
    this.trackGa4EcAction('view_item', {
      currency: productVariant.currencyCode,
      value: productVariant.price,
      items: [ viewItem ],
    })
  }

  trackGA4AddToCart(productVariant, product) {
    const addToCartItem = this.getTrackGA4ProductItem(product, { ...productVariant, quantity: 1 })
    this.trackGa4EcAction("add_to_cart", {
      currency: productVariant.currencyCode,
      value: productVariant.price,
      items: [ addToCartItem ],
    })
  }

  trackGA4BeginCheckout(order, couponName) {
    const items = order.lineItems.map(lineItem => this.getTrackGA4ProductItem(lineItem.product, { ...lineItem.productVariant, quantity: lineItem.quantity }))
    this.trackGa4EcAction("begin_checkout", {
      coupon: couponName || '',
      currency: order.currencyCode,
      value: order.totalPrice,
      items: items,
    })
  }

  trackGA4Purchase(order, couponName) {
    const items = order.lineItems.map(lineItem => this.getTrackGA4ProductItem(lineItem.product, { ...lineItem.productVariant, quantity: lineItem.quantity }))
    this.trackGa4EcAction("purchase", {
      currency: order.currencyCode,
      transaction_id: order.id,
      value: order.totalPrice,
      coupon: couponName || '',
      shipping: order.totalShipping,
      items: items,
    })
  }

  getTrackGtagProductItem(product, productVariant) {
    let item = {
      id: productVariant.id,
      name: this.getTrackProductName(productVariant, product),
      brand: product.vendor,
      category: product.category,
      variant: productVariant.title,
      quantity: productVariant.quantity,
      price: productVariant.price,
      google_business_vertical: 'retail',
    }
    return item
  }

  trackGtagViewProduct(productVariant, product) {
    const viewItem = this.getTrackGtagProductItem(product, { ...productVariant, quantity: 1 })
    this.gtag('event', 'view_item', {
      currency: productVariant.currencyCode,
      items: [ viewItem ]
    })
  }

  trackGtagAddToCart(productVariant, product) {
    const addToCartItem = this.getTrackGtagProductItem(product, { ...productVariant, quantity: 1 })
    this.gtag('event', 'add_to_cart', {
      currency: productVariant.currencyCode,
      items: [ addToCartItem ]
    })
  }

  trackGtagBeginCheckout(order, couponName) {
    const items = order.lineItems.map(lineItem => this.getTrackGtagProductItem(lineItem.product, { ...lineItem.productVariant, quantity: lineItem.quantity }))
    this.gtag('event', 'begin_checkout', {
      items: items,
      coupon: couponName || '',
      currency: order.currencyCode,
    })
  }

  trackGtagPurchase(order, couponName) {
    const items = order.lineItems.map(lineItem => this.getTrackGtagProductItem(lineItem.product, { ...lineItem.productVariant, quantity: lineItem.quantity }))
    this.gtag('event', 'purchase', {
      id: order.id,
      transaction_id: order.id,
      value: order.totalPrice,
      currency: order.currencyCode,
      shipping: order.totalShipping,
      coupon: couponName || '',
      items: items,
    })
  }

  trackGAGoogleAds(id, pageType, totalValue) {
    this.ga('set', 'dimension3', id)
    this.ga('set', 'dimension2', pageType)
    this.ga('set', 'dimension4', totalValue)
  }

  trackFbPixelWithProduct(action, productVariant, product, eventID, extArgs = {}) {
    if (product.id) {
      const params = {
        ...this.makeFbTrackContent(productVariant, product),
        ...extArgs
      }

      this.fbq('track', action, params, { eventID: eventID })
    }
  }

  /* ------------------------------------------ */

  getCountryFromLocalForGoogleAdsFeed = () => {
    const languageArray = window.navigator.language.split('-')
    return languageArray[1] ? languageArray[1].toUpperCase() : 'US'
  }


  clickProduct = (product, index, isQuickAdd) => {
    // FOR COLLECT
    this.collectTrack({
      category: EC_CATEGORY,
      action: 'Clicked Product',
      label: isQuickAdd ? 'quick_add' : undefined,
      params: {
        id: product.id,
        handle: product.handle,
        index
      }
    })
  }

  viewProduct = (productVariant, product, eventID) => {
    if (this.isBotTraffic()) {
      return
    }

    this.checkProductArgs(productVariant, product)

    // FOR GA
    this.trackGAGoogleAds(productVariant.id, 'product', productVariant.price)
    this.trackGAWithProduct(productVariant, product)
    this.trackGaEcAction('detail')
    this.trackGA4ViewProduct(productVariant, product)

    this.gaEvent({
      category: product.category,
      action: GA_EC_ACTION.VIEW_PRODUCT,
      label: this.getTrackProductName(productVariant, product),
      nonInteraction: true
    })

    // FOR GOOGLE ADS
    if (this.options.googleAdsConversionMap && this.options.googleAdsConversionMap.googleAdsConversionViewProductId) {
      this.gtagSendConversion(this.options.googleAdsConversionMap.googleAdsConversionViewProductId)
    }

    if (this.canInstallGtag() && Boolean(window.dataLayer) && window.navigator.language) {
      this.trackGtagViewProduct(productVariant, product)
    }

    // FOR SNAPCHAT
    this.snapchatTrack('VIEW_CONTENT')

    // FOR FB PIXEL
    this.trackFbPixelWithProduct('ViewContent', productVariant, product, eventID)

    if (this.hasCollect()) {
      const $storage = this.pluginHub.getStorage()

      const anonymousEmail = $storage.create('an_lg_email').getItem({})

      this.$collectClient.notifyFBTrack('ViewContent', {
        sourceId: productVariant.id,
        email: anonymousEmail && anonymousEmail.email,
        fbp: getCookieItem('_fbp'),
        fbc: getCookieItem('_fbc')
      }, eventID)
    }

    // FOR TIKTOK
    this.tiktokTrack('ViewContent', {
      content_type: 'product',
      content_id: productVariant.id,
      content_category: product.category,
      content_name: product.title,
      currency: this.currencyCode,
      price: productVariant.price,
      value: productVariant.price
    })

    // FOR BING
    this.bingTrackViewedItem(productVariant)

    // FOR COLLECT
    this.collectTrack({
      category: EC_CATEGORY,
      action: GA_EC_ACTION.VIEW_PRODUCT,
      label: this.getTrackProductName(productVariant, product),
      params: {
        ...this.makeCollectTrackContentProduct(product),
        variantId: productVariant.id,
        variantSku: productVariant.sku,
      }
    })
  }

  addToCart = (productVariant, product, quantity, { channel, lineItems, cartId } = {}, eventID) => {
    this.checkProductArgs(productVariant, product, quantity)

    // FOR GA
    this.trackGAGoogleAds(productVariant.id, 'cart', productVariant.price)
    this.trackGAWithProduct(productVariant, product, quantity)
    this.trackGaEcAction('add')
    this.trackGA4AddToCart(productVariant, product)

    this.gaEvent({
      category: product.category,
      action: GA_EC_ACTION.ADD_PRODUCT,
      label: this.getTrackProductName(productVariant, product),
      nonInteraction: false
    })

    // FOR CUSTOMIZED GA EVENT
    this.gaEvent({
      category: 'PathTracing',
      action: GA_EC_ACTION.ADD_PRODUCT,
      label: channel,
      nonInteraction: false
    })

    // FOR GOOGLE ADS
    if (this.options.googleAdsConversionMap && this.options.googleAdsConversionMap.googleAdsConversionAddToCartId) {
      this.gtagSendConversion(this.options.googleAdsConversionMap.googleAdsConversionAddToCartId)
    }

    if (this.canInstallGtag() && Boolean(window.dataLayer) && window.navigator.language) {
      this.trackGtagAddToCart(productVariant, product)
    }

    // FOR TIKTOK
    this.tiktokTrack('AddToCart', {
      content_type: 'product',
      content_id: productVariant.id,
      content_category: product.category,
      content_name: product.title,
      currency: this.currencyCode,
      quantity,
      price: productVariant.price,
      value: productVariant.price
    })

    // FOR BING
    this.bingTrackAddToCart(productVariant)

    // FOR SNAPCHAT
    this.snapchatTrack('ADD_CART')

    // FOR FB PIXEL
    this.trackFbPixelWithProduct('AddToCart', productVariant, product, eventID)

    const cartUrl = `${this.websiteOrigin}/store/email_redirect?_campaign=abandon_cart_before_checkout&_cartId=${cartId}`

    // FOR COLLECT
    this.collectTrack({
      category: EC_CATEGORY,
      action: GA_EC_ACTION.ADD_PRODUCT,
      label: this.getTrackProductName(productVariant, product),
      params: {
        ...this.makeCollectTrackContentLineItem(productVariant, product, quantity),
        checkoutUrl: cartUrl
      }
    })

  }

  initiateCheckout = (order, couponName, checkoutURL, eventID) => {
    // FOR GA
    this.gaEvent({
      category: 'All',
      action: 'Viewed Checkout - Contact information Page',
      label: 'victor',
      value: 0,
      nonInteraction: false
    })

    order.lineItems.forEach(({ productVariant, product, quantity }) => {
      this.checkProductArgs(productVariant, product, quantity)
      this.trackGAWithProduct(productVariant, product, quantity)
    })

    this.trackGaEcAction('checkout', { step: 1 })
    this.trackGA4BeginCheckout(order, couponName)

    this.gaEvent({
      category: 'EnhancedEcommerce',
      action: 'Started Order',
      label: 'victor',
      nonInteraction: false
    })

    // FOR GOOGLE ADS
    if (this.options.googleAdsConversionMap && this.options.googleAdsConversionMap.googleAdsConversionBeginCheckoutId) {
      this.gtagSendConversion(this.options.googleAdsConversionMap.googleAdsConversionBeginCheckoutId)
    }

    if (this.canInstallGtag() && Boolean(window.dataLayer) && window.navigator.language) {
      this.trackGtagBeginCheckout(order, couponName)
    }

    // FOR FB TRACK
    this.fbq('track', 'InitiateCheckout', {
      value: order.totalPrice,
      currency: this.currencyCode,
      content_type: 'product',
      content_ids: order.lineItems.map(({ productVariant }) => productVariant.id),
      num_items: order.totalQuantity
    }, { eventID: eventID })

    // FOR COLLECT
    this.collectTrack({
      category: EC_CATEGORY,
      action: GA_EC_ACTION.INITIATE_CHECKOUT,
      params: {
        ...this.makeCollectTrackContentOrder(order, couponName),
        checkoutUrl: `${this.websiteOrigin}${checkoutURL}`,
      }
    })

    // FOR TIKTOK
    this.tiktokTrack('InitiateCheckout', {
      contents: order.lineItems.map(({ productVariant, product, quantity }) => ({
        content_type: 'product',
        content_id: productVariant.id,
        content_category: product.category,
        content_name: product.title,
        price: productVariant.price,
        quantity,
      })),
      currency: this.currencyCode,
      value: order.totalPrice
    })

    // FOR BING
    this.bingTrackInitiateCheckout(order)
  }

  purchase = (order, couponName, user, eventID) => {
    // FOR GA
    this.gaEvent({
      category: 'All',
      action: 'Viewed Checkout - Receipt Page',
      label: 'victor',
      value: 0,
      nonInteraction: true
    })

    this.gaEvent({
      category: 'EnhancedEcommerce',
      action: 'Completed Order',
      label: 'victor',
      nonInteraction: false
    })

    // FOR GOOGLE ADS TRACK
    const $storage = this.pluginHub.getStorage()

    const { shippingAddress, email } = order
    const trackEmail = email || $storage.create('an_lg_email').getItem({}).email
    const trackShippingAddress = shippingAddress || $storage.create('an_address').getItem(null)

    if (trackEmail && trackShippingAddress) {
      const userData = {
        email: trackEmail,
        address: {
          first_name: trackShippingAddress.firstName,
          last_name: trackShippingAddress.lastName,
          street: `${trackShippingAddress.addressLine1} ${trackShippingAddress.addressLine2}`,
          city: trackShippingAddress.cityName,
          region: trackShippingAddress.stateName,
          postal_code: trackShippingAddress.zipCode,
          country: trackShippingAddress.countryCode
        }
      }

      if (trackShippingAddress.telNumber) {
        userData['phone_number'] = `${COUNTRY_MOBILE_PREFIX[trackShippingAddress.countryCode]}${trackShippingAddress.telNumber}`
      }

      this.gtag('set', 'user_data', userData)
    }

    let idList = null
    let totalValue = 0
    order.lineItems.forEach(({ productVariant, product, quantity }) => {
      this.checkProductArgs(productVariant, product, quantity)
      // ga 添加产品信息，需要在打点send之前
      this.trackGAWithProduct(productVariant, product, quantity)
      const id = productVariant.id
      idList = idList ? idList.concat(', ', id) : id
      totalValue += productVariant.price
    })

    this.trackGaEcAction('purchase', {
      id: order.id,
      revenue: order.totalPrice,
      shipping: order.totalShipping,
      tax: order.totalTax,
      coupon: couponName
    })
    this.trackGA4Purchase(order, couponName)

    this.trackGAGoogleAds(idList, 'purchase', totalValue)

    if (this.options.googleAdsConversionMap && this.options.googleAdsConversionMap.googleAdsConversionPurchaseId) {
      this.gtagSendConversion(this.options.googleAdsConversionMap.googleAdsConversionPurchaseId, {
        value: order.totalPrice,
        currency: this.currencyCode,
        transaction_id: order.id
      })
    }

    if (this.canInstallGtag() && Boolean(window.dataLayer) && window.navigator.language) {
      this.trackGtagPurchase(order, couponName)
    }

    // FOR SNAPCHAT TRACK
    this.snapchatTrack('PURCHASE', {
      currency: this.currencyCode,
      price: order.totalPrice,
      transaction_id: order.id
    })

    // FOR BING
    this.bingTrackPurchase(order)

    // FOR FB TRACK
    this.fbq('track', 'Purchase', {
      value: order.totalPrice,
      currency: this.currencyCode,
      content_type: 'product',
      content_ids: order.lineItems.map(({ productVariant }) => productVariant.id),
      num_items: order.totalQuantity
    }, { eventID: eventID })

    // FOR COLLECT
    this.collectTrack({
      category: EC_CATEGORY,
      action: GA_EC_ACTION.PURCHASE,
      params: this.makeCollectTrackContentOrder(order, couponName)
    })

    //FOR TIKTOK
    this.tiktokTrack('CompletePayment', {
      contents: order.lineItems.map(({ productVariant, product, quantity }) => ({
        content_type: 'product',
        content_id: productVariant.id,
        content_category: product.category,
        content_name: product.title,
        price: productVariant.price,
        quantity,
      })),
      currency: this.currencyCode,
      value: order.totalPrice
    })

    // close unpaid
    const unpaidInfoStorage = $storage.create('unpaid_info', { strategy: 'SESSION' })
    unpaidInfoStorage.setItem({
      ...unpaidInfoStorage.getItem({}),
      unpaid_buoy_closed: true
    })

    // purchase_count
    const purchaseCountStorage = $storage.create('purchase_count')
    const purchaseCount = purchaseCountStorage.getItem(0) + 1
    purchaseCountStorage.setItem(purchaseCount)
  }

  /* ---------------------------------- */
  trackEmail = (email, source) => {

    this.event({
      category: 'Email',
      action: email,
      label: source
    })
  }

  /* ---------------------------------- */
  trackPhone = (phone, source) => {
    this.event({
      category: 'Phone',
      action: phone,
      label: source
    })
  }

  /*
    track user performance by window.performance api
  */
  trackPerformance = (action, resources) => {
    this.collectTrack({
      category: 'Performance',
      action,
      params: resources
    })
  }

  trackImpression = ({
    eventName,
    eventId,
    eventParams = {}
  }) => {
    if (eventId) {
      this.collectTrack({
        category: 'Impression',
        action: eventName,
        params: {
          ...eventParams,
          id: eventId
        },
        isDebounce: true
      })
    }
  }

  clickMainResourceLocator = (resourceLocator, params) => {
    if (this.hasCollect()) {
      this.$collectClient.setResourceLocators(resourceLocator)
    }

    this.collectTrack({
      category: 'Channel',
      action: 'click',
      label: resourceLocator,
      params
    })
  }

  clickSubResourceLocator = (resourceLocator, params) => {
    if (this.hasCollect()) {
      this.$collectClient.setResourceLocators(null, resourceLocator)
    }

    this.collectTrack({
      category: 'Channel',
      action: 'click',
      label: resourceLocator,
      params
    })
  }

  /* ---------------------------------- */
  injectProps = {
    $track: {
      setPage: this.setPage,

      pv: this.pv,
      event: this.event,
      flush: this.flush,

      clickProduct: this.clickProduct,
      viewProduct: this.viewProduct,
      addToCart: this.addToCart,
      initiateCheckout: this.initiateCheckout,
      purchase: this.purchase,

      getTrackProductName: this.getTrackProductName,
      trackEmail: this.trackEmail,
      trackPhone: this.trackPhone,
      subscribeTrack: this.subscribeTrack,
      unsubscribeTrack: this.unsubscribeTrack,

      error: this.error,
      performance: this.trackPerformance,
      impression: this.trackImpression,
      clickMainResourceLocator: this.clickMainResourceLocator,
      clickSubResourceLocator: this.clickSubResourceLocator
    }
  }
}
